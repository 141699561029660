/* ===== BASE ====== */
/* CONTAINER'S MAX WIDTH */
/* FONTS */
/* FONT SIZES */
/* COLORS */
/* Leads Colors Leads */
/* Border Radius */
/* RESET AND NORMALIZE CSS */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  line-height: 1.5;
}

a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

iframe,
video {
  max-width: 100%;
  width: 100%;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

ol,
ul {
  list-style-type: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

hr {
  border: none;
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

@font-face {
  font-family: "avenir_nextregular";
  src: url("../fonts/avenir-next-regular-webfont.woff2") format("woff2"),
    url("../fonts/avenir-next-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "avenirnextmedium";
  src: url("../fonts/avenirnextmedium.eot");
  src: url("../fonts/avenirnextmedium.eot") format("embedded-opentype"),
    url("../fonts/avenirnextmedium.woff2") format("woff2"),
    url("../fonts/avenirnextmedium.woff") format("woff"),
    url("../fonts/avenirnextmedium.ttf") format("truetype"),
    url("../fonts/avenirnextmedium.svg#avenirnextmedium") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "avenirnextdemibold";
  src: url("../fonts/avenirnextdemibold.eot");
  src: url("../fonts/avenirnextdemibold.eot") format("embedded-opentype"),
    url("../fonts/avenirnextdemibold.woff2") format("woff2"),
    url("../fonts/avenirnextdemibold.woff") format("woff"),
    url("../fonts/avenirnextdemibold.ttf") format("truetype"),
    url("../fonts/avenirnextdemibold.svg#avenirnextdemibold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "icomoon";
  src: url("../icons/icomoon.eot");
  src: url("../icons/icomoon.eot") format("embedded-opentype"),
    url("../icons/icomoon.ttf") format("truetype"),
    url("../icons/icomoon.woff") format("woff"),
    url("../icons/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon-lms {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-share-off-filled:before {
  content: "\e925";
}

.icon-share-off:before {
  content: "\e922";
}

.icon-share:before {
  content: "\e924";
}

.icon-copy:before {
  content: "\e921";
}

.icon-save:before {
  content: "\e919";
}

.icon-reply:before {
  content: "\e917";
}

.icon-send:before {
  content: "\e918";
}

.icon-add-user:before {
  content: "\e900";
}

.icon-add:before {
  content: "\e901";
}

.icon-agent:before {
  content: "\e902";
}

.icon-arrow-down:before {
  content: "\e903";
}

.icon-arrow-left:before {
  content: "\e904";
}

.icon-arrow-right:before {
  content: "\e905";
}

.icon-arrow-up:before {
  content: "\e906";
}

.icon-calendar:before {
  content: "\e907";
}

.icon-calendly:before {
  content: "\e908";
}

.icon-close:before {
  content: "\e909";
}

.icon-delete:before {
  content: "\e90a";
}

.icon-double-arrow-down:before {
  content: "\e90b";
}

.icon-double-arrow-up:before {
  content: "\e90c";
}

.icon-download:before {
  content: "\e90d";
}

.icon-edit:before {
  content: "\e90e";
}

.icon-equal:before {
  content: "\e90f";
}

.icon-export:before {
  content: "\e910";
}

.icon-filter:before {
  content: "\e911";
}

.icon-search:before {
  content: "\e912";
}

.icon-sync:before {
  content: "\e913";
}

.icon-user:before {
  content: "\e914";
}

.icon-visibility-off:before {
  content: "\e915";
}

.icon-visibility-on:before {
  content: "\e916";
}

.icon-fullscreen-alt:before {
  content: "\e923";
}

.icon-user1:before {
  content: "\e91f";
}

.icon-list-numbered:before {
  content: "\e920";
}

.icon-mobile:before {
  content: "\e91b";
}

.icon-check-circle:before {
  content: "\e91e";
}

.icon-checkmark:before {
  content: "\e91d";
}

.icon-user-o:before {
  content: "\e91a";
}

.icon-envelope-o:before {
  content: "\e91c";
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: "avenirnextmedium";
  font-weight: normal;
  color: #212121;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "avenirnextdemibold";
  font-weight: normal;
  line-height: 1.15;
}

.fw-reg {
  font-family: "avenir_nextregular";
  font-weight: normal;
}

.fw-medium {
  font-family: "avenirnextmedium";
  font-weight: normal;
}

.fw-bold,
b,
strong {
  font-family: "avenirnextdemibold";
  font-weight: normal;
}

s {
  text-decoration-thickness: 2px;
}

a {
  text-decoration: none;
}
a:hover,
a:focus {
  text-decoration: none;
}

em,
i {
  font-style: italic;
}

a {
  color: #0a87cf;
}

@media screen and (min-width: 1900px) {
  body {
    font-size: 1.125rem;
  }
}
.main-content {
  background-color: #f9f9f9;
  width: 100%;
  height: 100vh;
  padding-left: 110px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .main-content {
    padding-left: 80px;
  }
}

.display-none {
  display: none !important;
}

.url-txt {
  word-break: break-all;
}

.lds-ellipsis {
  display: block;
  position: relative;
  top: 10px;
  left: 0;
  right: 0;
  width: 80px;
  height: 15px;
  margin: auto;
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0a87cf;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
  animation: lds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
    transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
    transform: translate(24px, 0);
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}

.lds-ring div {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 3px;
  border: 3px solid #0a87cf;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0a87cf transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fade:not(.show) {
  visibility: hidden;
}

/* ===== COMPONENTS ====== */
.sidebar {
  background-color: #23293f;
  position: fixed;
  top: 0;
  left: 0;
  width: 90px;
  height: 100vh;
  z-index: 11;
  -webkit-transition: all 03s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }

}
.sidebar .sidebar-logo {
  padding: 15px 23px;
}
.sidebar .sidebar-items .sidenav-item {
  padding: 10px 15px;
}
.sidebar .sidebar-items .sidenav-item a {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.sidebar .sidebar-items .sidenav-item a svg,
.sidebar .sidebar-items .sidenav-item a span {
  display: block;
  color: rgba(255, 255, 255, 0.5);
}
.sidebar .sidebar-items .sidenav-item a svg {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3px;
}
.sidebar .sidebar-items .sidenav-item a span {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.sidebar .sidebar-items .sidenav-item.side-nav-active {
  background-color: #2c3451;
}
.sidebar .sidebar-items .sidenav-item.side-nav-active a svg {
  color: #0a87cf;
}
.sidebar .sidebar-items .sidenav-item.side-nav-active a span {
  color: #fff;
}
.sidebar .logout {
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 100%;
}
.sidebar .logout .dropdown-parent {
  position: relative;
}
.sidebar .logout .dropdown-parent .dropdown-menu {
  border-color: #eee;
  padding: 0;
}
.sidebar .logout .dropdown-parent .dropdown-menu a {
  cursor: pointer;
  color: #212121;
  display: block;
  padding: 10px;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.sidebar .logout .dropdown-parent .dropdown-menu.active-custom {
  display: block;
  -webkit-transform: translate3d(82px, -35px, 0);
  transform: translate3d(82px, -35px, 0);
  top: 0;
  left: 0;
}
.sidebar .logout .dropdown-parent::after {
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-right: 8px solid #fff;
  border-left: none;
  position: absolute;
  right: 8px;
  bottom: 10px;
  opacity: 0;
}
.sidebar .logout .dropdown-parent.show::after {
  opacity: 1;
}
.sidebar .logout .avatar-box span {
  width: 35px;
  height: 35px;
}

.sidebar-menu-box {
  background-color: #2c3451;
  position: fixed;
  top: 0;
  left: 90px;
  width: 20px;
  height: 100vh;
  z-index: 10;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 768px) {
  .sidebar-menu-box {
    left: 60px;
  }
}
.sidebar-menu-box.active {
  width: 220px;
  z-index: 11;
}
.sidebar-menu-box.active .sidebar-menu-wrapper {
  opacity: 1;
  visibility: visible;
}
.sidebar-menu-box.active button.btn-toggle {
  right: -15px;
}
.sidebar-menu-box.active button.btn-toggle i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.sidebar-menu-box button.btn-toggle {
  position: absolute;
  top: 20px;
  right: -20px;
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
  color: #000;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  opacity: 1;
  z-index: 15;
  font-size: 25px;
}
.sidebar-menu-box .sidebar-menu-wrapper {
  opacity: 0;
  visibility: hidden;
  width: 220px;
  height: 100%;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.sidebar-menu-box .sidebar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 25px 20px 30px 20px;
}
.sidebar-menu-box .sidebar-title p {
  color: #fff;
  font-family: "avenirnextdemibold";
  font-size: 1.25rem;
}
.sidebar-menu-box .sidebar-title .controls-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sidebar-menu-box .sidebar-title .controls-group span {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-left: 10px;
}
.sidebar-menu-box .sidebar-title .controls-group span i {
  position: relative;
  text-align: center;
  width: 100%;
  font-size: 10px;
}
.sidebar-menu-box .sidebar-title .controls-group span.btn-sort i {
  font-size: 12px;
}
.sidebar-menu-box .sidebar-title .controls-group span.btn-sort i:nth-child(1) {
  bottom: -6px;
}
.sidebar-menu-box .sidebar-title .controls-group span.btn-sort i:nth-child(2) {
  top: -6px;
}
.sidebar-menu-box .sidebar-list ul .sub-menu {
  display: none;
}
.sidebar-menu-box .sidebar-list ul .sub-menu.open {
  display: block;
}
.sidebar-menu-box .sidebar-list ul li {
  cursor: pointer;
  position: relative;
  font-family: "avenirnextdemibold";
  font-size: 1rem;
  color: #fff;
  display: block;
  padding: 0 20px 25px 20px;
}
.sidebar-menu-box .sidebar-list ul li.has-menu::after {
  content: "\e905";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  position: absolute;
  top: -6px;
  right: 15px;
}
.sidebar-menu-box .sidebar-list ul li.has-menu.open::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sidebar-menu-box .sidebar-list ul li a {
  color: inherit;
}
.sidebar-menu-box .sidebar-list ul li a.active {
  color: #0a87cf;
}
.sidebar-menu-box .sidebar-list ul li ul {
  padding-top: 10px;
}
.sidebar-menu-box .sidebar-list ul li ul li {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
  padding: 10px 20px;
}
.sidebar-menu-box .sidebar-list ul li:last-of-type {
  padding-bottom: 0;
}

.sidebar-overlay,
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 11;
}
.sidebar-overlay.active,
.drawer-overlay.active {
  width: 100%;
  height: 100%;
}

.topbar-menu {
  background-color: #fff;
  padding: 15px 30px;
  height: 72px;
}
.topbar-menu .col:nth-child(1) {
  width: 35%;
  max-width: 35%;
}
.topbar-menu .top-project-info-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.topbar-menu .top-project-info-box .project-name,
.topbar-menu .top-project-info-box .members-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.topbar-menu .top-project-info-box .project-name {
  padding-right: 15px;
}
.topbar-menu .top-project-info-box .project-name h1 {
  font-size: 1.25rem;
}
.topbar-menu .top-project-info-box .members-list {
  cursor: pointer;
  padding-left: 20px;
}
.topbar-menu .top-project-info-box .members-list .avatar-box span {
  border: 1px solid #fff;
}
.topbar-menu .top-project-info-box .members-list .member {
  margin-left: -15px;
}
.topbar-menu .top-project-info-box .members-list .member-count {
  margin-left: 10px;
}
.topbar-menu .top-project-info-box .members-list .member-count p {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.topbar-menu .top-button-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.topbar-menu .top-button-box .c-btn {
  margin-left: 10px;
}
.topbar-menu .top-button-box .c-btn:first-child {
  margin-left: 0;
}

.breadcrumb-menu {
  height: 81px;
  padding: 20px 30px;
}
.breadcrumb-menu .breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.breadcrumb-menu .breadcrumbs a,
.breadcrumb-menu .breadcrumbs span {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.breadcrumb-menu .breadcrumbs a.back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.breadcrumb-menu .breadcrumbs a.back span {
  color: #212121;
}
.breadcrumb-menu .breadcrumbs a.back .icon-s {
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
  color: #000;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  opacity: 1;
  font-size: 25px;
}
.breadcrumb-menu .breadcrumbs a.back span.back-text {
  margin-left: 10px;
}
.breadcrumb-menu .breadcrumbs a.link {
  color: #0a87cf;
  margin-left: 20px;
}
.breadcrumb-menu .breadcrumbs a.link i {
  font-style: normal;
  color: #212121;
  margin-left: 8px;
  margin-right: 8px;
}
.breadcrumb-menu .button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.breadcrumb-menu .button-group button {
  margin-left: 13px;
}
.breadcrumb-menu .button-group button:first-of-type {
  margin-left: 0;
}
.breadcrumb-menu .button-group .btn-chat-email-group button {
  position: relative;
  background-color: rgba(10, 135, 207, 0.1);
  color: #0a87cf;
  border-radius: 50%;
  font-size: 1rem;
  width: 40px;
  height: 40px;
  margin-right: 13px;
}
.breadcrumb-menu .button-group .btn-chat-email-group button span {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #e60000;
  color: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-family: "avenirnextdemibold";
  font-size: 0.625rem;
}
.breadcrumb-menu .button-group .btn-chat-email-group button span.empty {
  background-color: #0a87cf;
}

.overview-menu {
  padding: 20px 30px;
}
.overview-menu .breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.overview-menu .breadcrumbs a,
.overview-menu .breadcrumbs span {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.overview-menu .breadcrumbs a.back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.overview-menu .breadcrumbs a.back span {
  color: #212121;
}
.overview-menu .breadcrumbs a.back .icon-s {
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
  color: #000;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  opacity: 1;
  font-size: 25px;
}
.overview-menu .breadcrumbs a.back span.back-text {
  margin-left: 10px;
}
.overview-menu .breadcrumbs a.link {
  color: #0a87cf;
  margin-left: 20px;
}
.overview-menu .breadcrumbs a.link i {
  font-style: normal;
  color: #212121;
  margin-left: 8px;
  margin-right: 8px;
}
.overview-menu .overview-control-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.overview-menu .overview-control-box .col-left {
  width: auto;
}
.overview-menu .overview-control-box .col-right {
  width: 100%;
}
.overview-menu .overview-control-box .button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.overview-menu .overview-control-box .button-group .search-box,
.overview-menu .overview-control-box .button-group .date-range-picker-box,
.overview-menu .overview-control-box .button-group .filter-box {
  margin-right: 10px;
}
.overview-menu .overview-control-box .button-group .search-box {
  width: 250px;
}
.overview-menu .overview-control-box .button-group .search-box .search-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  color: rgba(33, 33, 33, 0.5);
  border-radius: 10px;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  padding: 10px 15px;
}
.overview-menu .overview-control-box .button-group .search-box .search-btn i {
  font-size: 1.25rem;
  margin-right: 6px;
}
.overview-menu .overview-control-box .button-group .search-box .input-search {
  position: relative;
}
.overview-menu
  .overview-control-box
  .button-group
  .search-box
  .input-search
  input {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 35px;
  padding-right: 65px;
}
.overview-menu
  .overview-control-box
  .button-group
  .search-box
  .input-search
  .icon-search,
.overview-menu
  .overview-control-box
  .button-group
  .search-box
  .input-search
  .controls {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.overview-menu .overview-control-box .button-group .search-box .input-search i {
  color: rgba(33, 33, 33, 0.5);
  font-size: 1.25rem;
}
.overview-menu
  .overview-control-box
  .button-group
  .search-box
  .input-search
  .icon-search {
  left: 10px;
}
.overview-menu
  .overview-control-box
  .button-group
  .search-box
  .input-search
  .controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 15px;
  opacity: 0;
  visibility: hidden;
}
.overview-menu
  .overview-control-box
  .button-group
  .search-box
  .input-search
  .controls
  i {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  font-size: 0.875rem;
}
.overview-menu
  .overview-control-box
  .button-group
  .search-box
  .input-search
  .controls
  i.icon-close {
  background-color: rgba(10, 135, 207, 0.1);
  color: #0a87cf;
}
.overview-menu
  .overview-control-box
  .button-group
  .search-box
  .input-search
  .controls
  i.icon-arrow-right {
  background-color: #0a87cf;
  color: #fff;
}
.overview-menu
  .overview-control-box
  .button-group
  .search-box.active
  .input-search
  .controls {
  opacity: 1;
  visibility: visible;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle-active,
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker.rs-picker-focused {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle-wrapper {
  background-color: #0a87cf;
  border: none;
  border-radius: 10px;
  width: 280px;
  outline: none !important;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle-wrapper
  .rs-btn-close {
  right: 15px !important;
  top: 10px !important;
  color: #fff;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle-wrapper
  .rs-btn.rs-btn-subtle.rs-btn-lg {
  background-color: transparent !important;
  outline: none !important;
  padding: 6px 15px !important;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle-wrapper
  .rs-btn.rs-btn-subtle.rs-btn-lg
  .rs-picker-toggle-textbox {
  background-color: transparent;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range {
  margin-left: 12px;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range
  .rs-picker-toggle-wrapper {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range
  .rs-picker-toggle-wrapper
  .rs-picker-toggle.rs-btn-lg {
  background-color: transparent;
  height: 100%;
  width: 100%;
  padding: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range
  .rs-picker-toggle-wrapper
  .rs-picker-toggle.rs-btn-lg:hover {
  background-color: #0a87cf;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range
  .rs-picker-toggle-wrapper
  .rs-picker-toggle.rs-btn-lg:hover
  svg {
  color: #fff;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range
  .rs-picker-toggle-wrapper
  .rs-picker-toggle.rs-btn-lg
  input {
  background-color: transparent !important;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range
  .rs-picker-toggle-wrapper
  .rs-picker-toggle.rs-btn-lg
  input:focus {
  outline: none !important;
  border-color: transparent;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range
  .rs-picker-toggle-wrapper
  .rs-picker-toggle.rs-btn-lg
  input,
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range
  .rs-picker-toggle-wrapper
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-placeholder,
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range
  .rs-picker-toggle-wrapper
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-value {
  opacity: 0;
  display: none;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box.icon-only-date-range
  .rs-picker-toggle-wrapper
  .rs-picker-toggle.rs-btn-lg
  svg {
  margin-left: 0;
  right: unset;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg {
  height: 37px;
  line-height: unset;
  border: none;
  background-color: transparent;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-value,
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-placeholder {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff !important;
  font-family: "avenirnextdemibold";
  font-weight: normal;
  font-size: 0.875rem;
  padding-left: 25px;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-textbox {
  background-color: #ededed;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-textbox::-webkit-input-placeholder {
  color: transparent;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-textbox::-moz-placeholder {
  color: transparent;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-textbox:-ms-input-placeholder {
  color: transparent;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-textbox::-ms-input-placeholder {
  color: transparent;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-textbox,
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-textbox::placeholder {
  color: transparent;
}
.overview-menu
  .overview-control-box
  .button-group
  .date-range-picker-box
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-caret {
  color: #fff;
  font-size: 0.875rem;
  top: 7px;
  left: 13px;
}
.overview-menu .overview-control-box .button-group .filter-box {
  position: relative;
}
.overview-menu .overview-control-box .button-group .filter-box .filter-btn {
  position: relative;
  background-color: #0a87cf;
  color: #fff;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  height: 37px;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.overview-menu .overview-control-box .button-group .filter-box .filter-btn i {
  margin-right: 8px;
  font-size: 1.125rem;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-btn
  span {
  background-color: #fff;
  color: #0a87cf;
  border-radius: 50%;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 10;
  padding: 20px;
  min-width: 650px;
  display: none;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown.active {
  display: block;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-title {
  position: relative;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-title
  p {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-title
  i {
  position: absolute;
  top: calc(50% - 5px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  font-size: 1.25rem;
  cursor: pointer;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list {
  max-height: 400px;
  overflow-y: auto;
  padding-bottom: 20px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 10px;
  padding: 12px 15px;
  margin-bottom: 15px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group:last-of-type {
  margin-bottom: 0;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group.active {
  background-color: rgba(10, 135, 207, 0.1);
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group.active
  .filter-name
  i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-name {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-name
  span {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-name
  i {
  font-size: 1.25rem;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-name
  .total-count,
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-name
  .count {
  background-color: transparent;
  color: rgba(33, 33, 33, 0.5);
  border-radius: 50%;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-name
  .total-count {
  right: 30px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-name
  .count {
  display: none;
  right: 30px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-name
  .count.active {
  background-color: #0a87cf;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-check-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s  ease-in-out;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-check-list
  .check-col {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-check-list
  .checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 10px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-check-list
  .checkbox-group
  input {
  margin-left: 15px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .filter-check-list
  .checkbox-group
  label {
  padding: 8px 15px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-control-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-shadow: 0 -1px 1px 0 rgba(33, 33, 33, 0.1);
  box-shadow: 0 -1px 1px 0 rgba(33, 33, 33, 0.1);
  padding: 20px 30px 0 30px;
  margin-left: -20px;
  margin-right: -20px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-control-box
  span {
  cursor: pointer;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-left: 20px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-control-box
  span.btn-reset {
  color: #212121;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-control-box
  span.btn-apply {
  color: #0a87cf;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box
  .filter-control-box
  span.btn-apply.disabled {
  color: rgba(10, 135, 207, 0.4);
  cursor: default;
  pointer-events: none;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box.filter-normal
  .filter-btn {
  background-color: #fff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  color: rgba(33, 33, 33, 0.5);
  border-radius: 10px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box.filter-normal
  .filter-dropdown {
  min-width: 250px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box.filter-normal
  .filter-dropdown
  .filter-list
  .checkbox-group {
  margin-bottom: 10px;
}
.overview-menu
  .overview-control-box
  .button-group
  .filter-box.filter-normal
  .filter-dropdown
  .filter-list
  .checkbox-group:last-child {
  margin-bottom: 0;
}
.overview-menu .overview-control-box .button-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.overview-menu .overview-control-box .button-toggle p {
  font-family: "avenirnextdemibold";
  font-size: 1rem;
  margin-right: 10px;
}
.overview-menu .overview-control-box .button-toggle .switch-tggle {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}
.overview-menu .overview-control-box .button-toggle .switch-tggle .slider {
  position: relative;
  cursor: pointer;
  background-color: rgba(33, 33, 33, 0.2);
  border-radius: 35px;
  width: 47px;
  height: 22px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.overview-menu
  .overview-control-box
  .button-toggle
  .switch-tggle
  .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0;
  bottom: 3px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}
.overview-menu
  .overview-control-box
  .button-toggle
  .switch-tggle.active
  .slider {
  background-color: #0a87cf;
}
.overview-menu
  .overview-control-box
  .button-toggle
  .switch-tggle.active
  .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
}
.overview-menu .overview-control-box .button-toggle .switch-tggle i {
  color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.overview-menu .overview-control-box .button-toggle .switch-tggle i.fa-check {
  right: 8px;
  font-size: 10px;
}
.overview-menu .overview-control-box .button-toggle .switch-tggle i.fa-times {
  left: 8px;
  font-size: 12px;
}

.overview-menu-w-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.overview-menu-w-breadcrumb div:first-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.overview-menu-w-breadcrumb .breadcrumb-overview {
  padding-right: 30px;
}

.overview-kpi-box {
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
  padding: 0 30px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
.overview-kpi-box .status-list-box {
  overflow: hidden;
}
.overview-kpi-box .status-list-box .status-list {
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}
.overview-kpi-box .status-list-box .status-list .swiper-slide:first-child .box {
  padding-top: 15px;
}
.overview-kpi-box
  .status-list-box
  .status-list
  .swiper-slide:first-child
  .box
  .status-title,
.overview-kpi-box
  .status-list-box
  .status-list
  .swiper-slide:first-child
  .box
  .status-result {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.overview-kpi-box
  .status-list-box
  .status-list
  .swiper-slide:first-child
  .box
  .status-result
  .result-box {
  margin-right: 0;
}
.overview-kpi-box
  .status-list-box
  .status-list
  .swiper-slide:first-child
  .box
  .status-result
  .result-box
  .result-title {
  display: none;
}
.overview-kpi-box
  .status-list-box
  .status-list
  .swiper-slide:first-child
  .box
  .status-result
  .result-box
  .result-count {
  color: #0a87cf;
  font-size: 1rem;
}
.overview-kpi-box .status-list-box .status-list .box {
  background: rgba(10, 135, 207, 0.06);
  border-radius: 10px;
  padding: 5px 15px;
  height: 75px;
  cursor: pointer;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}
.overview-kpi-box .status-list-box .status-list .box .status-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  margin-bottom: -3px;
}
.overview-kpi-box .status-list-box .status-list .box .status-title .main-title {
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 1rem;
}
.overview-kpi-box .status-list-box .status-list .box .status-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.overview-kpi-box
  .status-list-box
  .status-list
  .box
  .status-result
  .result-box {
  position: relative;
  margin-top: 2px;
  margin-right: 15px;
}
.overview-kpi-box
  .status-list-box
  .status-list
  .box
  .status-result
  .result-box
  i {
  color: rgb(155, 192, 232);
  font-size: 0.75rem;
}
.overview-kpi-box
  .status-list-box
  .status-list
  .box
  .status-result
  .result-box
  .result-title {
  position: relative;
  display: block;
  color: #212121;
  font-family: "avenir_nextregular";
  font-size: 0.75rem;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90px;
  margin-bottom: -2px;
}
.overview-kpi-box
  .status-list-box
  .status-list
  .box
  .status-result
  .result-box
  .result-hover {
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  position: absolute;
  top: -27px;
  left: 0;
  padding: 5px 8px;
  text-transform: capitalize;
  font-family: "avenir_nextregular";
  font-size: 0.75rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  z-index: 15;
}
.overview-kpi-box
  .status-list-box
  .status-list
  .box
  .status-result
  .result-box
  .result-count {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  white-space: nowrap;
}
.overview-kpi-box
  .status-list-box
  .status-list
  .box
  .status-result
  .result-box
  .result-title:hover
  + .result-hover {
  opacity: 1;
  visibility: visible;
}
.overview-kpi-box .status-list-box .kpiSwiper .swiper-slide {
  width: auto;
}
.overview-kpi-box .status-list-box .swiper-custom-btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff !important;
  border: 1px solid #f5f4f4;
  cursor: pointer;
  height: 100%;
  width: 30px;
  padding: 0;
  z-index: 1;
}
.overview-kpi-box .status-list-box .swiper-custom-btn i {
  color: #212121;
  font-size: 1.25rem;
}
.overview-kpi-box .status-list-box .swiper-custom-btn.prevSlide {
  left: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.overview-kpi-box .status-list-box .swiper-custom-btn.nextSlide {
  right: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.overview-kpi-box .status-list-box .swiper-custom-btn.swiper-button-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.overview-kpi-box .status-list-box .swiper-custom-btn.swiper-button-lock {
  display: block;
  cursor: not-allowed;
  opacity: 0.4;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-1 {
  background: rgba(0, 179, 254, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-1
  .status-title
  .main-title {
  color: #00b3fe;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-2 {
  background: rgba(22, 117, 224, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-2
  .status-title
  .main-title {
  color: #1675e0;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-3 {
  background: rgba(80, 100, 255, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-3
  .status-title
  .main-title {
  color: #5064ff;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-4 {
  background: rgba(72, 163, 158, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-4
  .status-title
  .main-title {
  color: #48a39e;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-5 {
  background: rgba(136, 150, 247, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-5
  .status-title
  .main-title {
  color: #8896f7;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-6 {
  background: rgba(157, 20, 198, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-6
  .status-title
  .main-title {
  color: #9d14c6;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-7 {
  background: rgba(24, 150, 136, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-7
  .status-title
  .main-title {
  color: #189688;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-8 {
  background: rgba(96, 198, 8, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-8
  .status-title
  .main-title {
  color: #60c608;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-9 {
  background: rgba(203, 152, 13, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-9
  .status-title
  .main-title {
  color: #cb980d;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-10 {
  background: rgba(219, 0, 0, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-10
  .status-title
  .main-title {
  color: #db0000;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-11 {
  background: rgba(251, 82, 0, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-11
  .status-title
  .main-title {
  color: #fb5200;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-12 {
  background: rgba(248, 176, 3, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-12
  .status-title
  .main-title {
  color: #f8b003;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-13 {
  background: rgba(4, 155, 167, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-13
  .status-title
  .main-title {
  color: #049ba7;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-14 {
  background: rgba(232, 72, 61, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-14
  .status-title
  .main-title {
  color: #e8483d;
}
.overview-kpi-box.leads-kpi .status-list-box .status-list .box.kpi-color-15 {
  background: rgba(167, 84, 4, 0.06);
}
.overview-kpi-box.leads-kpi
  .status-list-box
  .status-list
  .box.kpi-color-15
  .status-title
  .main-title {
  color: #a75404;
}
.overview-kpi-box.influencer-kpi .status-list-box .status-list .box {
  position: relative;
}
.overview-kpi-box.influencer-kpi .status-list-box .status-list .box::after {
  content: "";
  background-repeat: no-repeat;
  background-position: 2px;
  background-size: 35px 35px;
  position: absolute;
  top: -6px;
  right: 0;
  width: 30px;
  height: 30px;
  opacity: 0.1;
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-total {
  background: rgba(0, 179, 254, 0.06);
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-total
  .status-title
  .main-title {
  color: #00b3fe;
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-facebook {
  background: rgba(24, 119, 242, 0.06);
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-facebook
  .status-title
  .main-title {
  color: #1877f2;
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-facebook::after {
  background-image: url("/facebook.svg");
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-instagram {
  background: rgba(185, 50, 131, 0.06);
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-instagram
  .status-title
  .main-title {
  color: #b93283;
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-instagram::after {
  background-image: url("/instagram.svg");
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-twitter {
  background: rgba(0, 179, 244, 0.06);
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-twitter
  .status-title
  .main-title {
  color: #00b3f4;
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-twitter::after {
  background-image: url("/twitter.svg");
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-youtube {
  background: rgba(244, 66, 54, 0.06);
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-youtube
  .status-title
  .main-title {
  color: #f44236;
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-youtube::after {
  background-image: url("/youtube.svg");
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-tiktok {
  background: rgba(33, 3, 24, 0.06);
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-tiktok
  .status-title
  .main-title {
  color: #210318;
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-tiktok::after {
  background-image: url("/tiktok.svg");
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-kumu {
  background: rgba(119, 63, 229, 0.06);
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-kumu
  .status-title
  .main-title {
  color: #773fe5;
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-kumu::after {
  background-image: url("/kumu.svg");
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-others {
  background: rgba(72, 163, 158, 0.06);
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-others
  .status-title
  .main-title {
  color: #48a39e;
}
.overview-kpi-box.influencer-kpi
  .status-list-box
  .status-list
  .box.kpi-color-others::after {
  background-image: url("/others.svg");
}

.manage-template-controls {
  padding: 0 30px;
}
.manage-template-controls .controls-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
}
.manage-template-controls .controls-btn .c-btn {
  margin-right: 12px;
}
.manage-template-controls .controls-btn .c-btn:last-of-type {
  margin-right: 0;
  float: right;
  margin-left: auto;
}

.side-drawer-main {
  background-color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -650px;
  width: 650px;
  height: 100%;
  z-index: 12;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.side-drawer-main.active {
  right: 0;
}
.side-drawer-main.active.parameters-active {
  right: 250px;
}
.side-drawer-main.active.decks-active {
  right: 300px;
}
.side-drawer-main .side-drawer-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  padding: 25px 30px;
}
.side-drawer-main .side-drawer-title h3 {
  font-size: 1.25rem;
}
.side-drawer-main .side-drawer-title span {
  cursor: pointer;
  font-size: 25px;
}
.side-drawer-main .side-drawer-title p.ig-add-parameter {
  cursor: pointer;
  font-weight: 500;
}
.side-drawer-main .side-drawer-title p.ig-add-parameter i {
  font-size: 0.75rem;
  margin-right: 5px;
}
.side-drawer-main .side-drawer-tabs .control-tabs-box {
  position: relative;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 3;
}
.side-drawer-main .side-drawer-tabs .control-tabs-box .tabs-btn {
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 15px 30px;
  margin-bottom: -0.8px;
}

.side-drawer-main .side-drawer-tabs .control-tabs-box .tabs-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
  background-color: transparent;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.side-drawer-main .side-drawer-tabs .control-tabs-box .tabs-btn p {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .side-drawer-main .side-drawer-tabs .control-tabs-box .tabs-btn p {
    font-size: 0.5rem;
  }
}
.side-drawer-main .side-drawer-tabs .control-tabs-box .tabs-btn.active {
  background: rgba(10, 135, 207, 0.06);
  color: #0a87cf;
}
.side-drawer-main .side-drawer-tabs .control-tabs-box .tabs-btn.active::after {
  background-color: #0a87cf;
}
.side-drawer-main .side-drawer-tabs .content-tabs-box {
  padding: 20px 30px 30px 30px;
}
.side-drawer-main .side-drawer-tabs .content-tabs-box {
  position: relative;
  height: calc(100vh - 195px);
  overflow-y: auto;
}
.side-drawer-main .side-drawer-tabs .content-tabs-box .loading-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.side-drawer-main .side-drawer-tabs .content-tabs-box .tabs-content {
  display: none;
}
.side-drawer-main .side-drawer-tabs .content-tabs-box .tabs-content.active {
  display: block;
}
.side-drawer-main .forms-tab {
  margin-bottom: 10px;
}
.side-drawer-main .forms-tab .other-platform {
  display: none;
  margin-top: 15px;
}
.side-drawer-main .forms-tab .other-platform.active {
  display: block;
}
.side-drawer-main .tab-form-btn-group {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  padding: 15px 30px;
}
.side-drawer-main .tab-form-btn-group .drawer-btn {
  cursor: pointer;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-left: 30px;
  display: inline-block !important;
}
.side-drawer-main .tab-form-btn-group .cancel-btn {
  color: #212121;
}
.side-drawer-main .tab-form-btn-group .save-btn {
  color: #0a87cf;
}
.side-drawer-main .tab-form-btn-group .delete-btn {
  color: #e60000;
}
.side-drawer-main .tab-form-btn-group .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.side-drawer-main .side-drawer-interval .content-tabs-box {
  height: calc(100vh - 140px) !important;
}
.side-drawer-main .side-drawer-interval .plain-btn {
  color: #0a87cf;
  cursor: pointer;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.side-drawer-main .side-drawer-interval .plain-btn i {
  margin-right: 5px;
  font-size: 1rem;
}
.side-drawer-main .side-drawer-interval .ig-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}
.side-drawer-main .side-drawer-interval .ig-title p {
  color: #212121;
  font-family: "avenirnextdemibold";
  font-size: 1rem;
}
.side-drawer-main .side-drawer-interval .ig-title .ig-add-parameter {
  cursor: pointer;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  color: #23a7f4;
  font-weight: 600;
  font-size: 0.75rem;
  text-decoration: none;
}
.side-drawer-main .side-drawer-interval .ig-title .ig-add-parameter i {
  margin-right: 5px;
}
.side-drawer-main .side-drawer-interval .parameter-default {
  margin-bottom: 25px;
}
.side-drawer-main
  .side-drawer-interval
  .parameter-default
  .parameters
  .p-control {
  opacity: 0;
  visibility: hidden;
}
.side-drawer-main
  .side-drawer-interval
  .parameter-default
  .parameters
  .p-sign
  span {
  cursor: default;
}
.side-drawer-main .side-drawer-interval .parameters-group.parameter-scroll {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.side-drawer-main .side-drawer-interval .parameters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 20px 0 0 0;
  margin-left: -5px;
  margin-right: -5px;
}
.side-drawer-main .side-drawer-interval .parameters div {
  padding-left: 5px;
  padding-right: 5px;
}
.side-drawer-main .side-drawer-interval .parameters .p-name,
.side-drawer-main .side-drawer-interval .parameters .p-sign,
.side-drawer-main .side-drawer-interval .parameters .p-field,
.side-drawer-main .side-drawer-interval .parameters .p-control {
  margin-bottom: 20px;
}
.side-drawer-main .side-drawer-interval .parameters .p-name {
  width: 30%;
}
.side-drawer-main .side-drawer-interval .parameters .p-name p {
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
}
.side-drawer-main .side-drawer-interval .parameters .p-sign {
  position: relative;
}
.side-drawer-main .side-drawer-interval .parameters .p-sign span {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #212121;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 42px;
  height: 42px;
  font-size: 12px;
}
.side-drawer-main .side-drawer-interval .parameters .p-sign .hover-signs {
  background-color: #fff;
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  position: absolute;
  top: -15px;
  left: 35px;
  padding: 15px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
}
.side-drawer-main .side-drawer-interval .parameters .p-sign .hover-signs div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.side-drawer-main .side-drawer-interval .parameters .p-sign .hover-signs div p {
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
  padding-left: 10px;
}
.side-drawer-main
  .side-drawer-interval
  .parameters
  .p-sign
  .hover-signs
  div:last-of-type {
  margin-bottom: 0;
}
.side-drawer-main
  .side-drawer-interval
  .parameters
  .p-sign
  .hover-signs.active {
  opacity: 1;
  visibility: visible;
}
.side-drawer-main .side-drawer-interval .parameters .p-field {
  position: relative;
  width: 70%;
}
.side-drawer-main .side-drawer-interval .parameters .p-field .error-field {
  position: absolute;
  top: 45px;
  font-size: 0.75rem;
  color: red;
}
.side-drawer-main .side-drawer-interval .parameters .p-field .input-suggested {
  position: absolute;
  left: 5px;
  right: 5px;
  background-color: #fff;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  z-index: 10;
  padding: 0;
}
.side-drawer-main
  .side-drawer-interval
  .parameters
  .p-field
  .input-suggested
  .suggested-item {
  cursor: pointer;
  border-bottom: 1px solid #eee;
  font-size: 0.75rem;
  padding: 5px 10px;
}
.side-drawer-main
  .side-drawer-interval
  .parameters
  .p-field
  .input-suggested
  .suggested-item:last-of-type {
  border-bottom: none;
}
.side-drawer-main .side-drawer-interval .parameters .p-control {
  text-align: right;
}
.side-drawer-main .side-drawer-interval .parameters .p-control button {
  background-color: transparent;
  border-color: transparent;
  text-align: right;
  width: 30px;
  height: 30px;
  padding: 0 5px 0 0;
}
.side-drawer-main .side-drawer-interval .parameters .p-control button i {
  font-size: 16px;
}
.side-drawer-main .manage-deck-tab .top-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 25px;
}
.side-drawer-main .manage-deck-tab .search-box {
  width: 250px;
}
.side-drawer-main .manage-deck-tab .search-box .search-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  color: rgba(33, 33, 33, 0.5);
  border-radius: 10px;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  padding: 10px 15px;
}
.side-drawer-main .manage-deck-tab .search-box .search-btn i {
  font-size: 1.25rem;
  margin-right: 6px;
}
.side-drawer-main .manage-deck-tab .search-box .input-search {
  position: relative;
}
.side-drawer-main .manage-deck-tab .search-box .input-search input {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 35px;
  padding-right: 65px;
}
.side-drawer-main .manage-deck-tab .search-box .input-search .icon-search,
.side-drawer-main .manage-deck-tab .search-box .input-search .controls {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.side-drawer-main .manage-deck-tab .search-box .input-search i {
  color: rgba(33, 33, 33, 0.5);
  font-size: 1.25rem;
}
.side-drawer-main .manage-deck-tab .search-box .input-search .icon-search {
  left: 10px;
}
.side-drawer-main .manage-deck-tab .search-box .input-search .controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 15px;
  opacity: 0;
  visibility: hidden;
}
.side-drawer-main .manage-deck-tab .search-box .input-search .controls i {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  font-size: 0.875rem;
}
.side-drawer-main
  .manage-deck-tab
  .search-box
  .input-search
  .controls
  i.icon-close {
  background-color: rgba(10, 135, 207, 0.1);
  color: #0a87cf;
}
.side-drawer-main
  .manage-deck-tab
  .search-box
  .input-search
  .controls
  i.icon-arrow-right {
  background-color: #0a87cf;
  color: #fff;
}
.side-drawer-main .manage-deck-tab .search-box.active .input-search .controls {
  opacity: 1;
  visibility: visible;
}
.side-drawer-main .manage-deck-tab .plain-btn {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.side-drawer-main .manage-deck-tab .plain-btn i {
  font-size: 1.125rem;
  margin-right: 5px;
}
.side-drawer-main .manage-deck-tab .deck-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.side-drawer-main .manage-deck-tab .deck-item .d1 .checkbox-group input {
  position: relative;
}
.side-drawer-main .manage-deck-tab .deck-item .d2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 85%;
  padding-left: 15px;
  padding-right: 15px;
}
.side-drawer-main .manage-deck-tab .deck-item .d2 p.deck-name {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-bottom: -5px;
}
.side-drawer-main .manage-deck-tab .deck-item .d2 span.deck-update {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
}
.side-drawer-main .manage-deck-tab .deck-item .d2 span.deck-link {
  position: relative;
  display: block;
  color: #0a87cf;
  font-family: "avenirnextmedium";
  font-size: 0.875rem;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.side-drawer-main .manage-deck-tab .deck-item .d2 a.toast-text {
  position: absolute;
  left: 5px;
  bottom: -15px;
  background-color: #0a87cf;
  color: #fff;
  border-radius: 4px;
  padding: 2px 6px;
  white-space: unset !important;
  word-break: unset !important;
  font-size: 0.75rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.side-drawer-main .manage-deck-tab .deck-item .d2 a.toast-text::before {
  content: "";
  position: absolute;
  top: -5px;
  border-right: solid 5px transparent;
  border-left: solid 5px transparent;
  border-bottom: solid 5px #0a87cf;
}
.side-drawer-main .manage-deck-tab .deck-item .d2 div {
  position: relative;
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}
.side-drawer-main .manage-deck-tab .deck-item .d2 div:first-of-type {
  cursor: pointer;
}
.side-drawer-main .manage-deck-tab .deck-item .d2 div:last-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.side-drawer-main
  .manage-deck-tab
  .deck-item
  .d2
  div:last-of-type:hover
  a.toast-text {
  opacity: 1;
  visibility: visible;
}
.side-drawer-main .manage-deck-tab .deck-item .d3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  transition: 0.1s ease;
}
.side-drawer-main .manage-deck-tab .deck-item .d3 button {
  padding: 4px 6px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-left: 4px;
  margin-right: 4px;
}
.side-drawer-main .manage-deck-tab .deck-item .d3 button i {
  font-size: 18px;
  margin-right: 0;
}
.side-drawer-main .manage-deck-tab .deck-item .d3 button:first-of-type i {
  font-size: 17px;
}
.side-drawer-main .manage-deck-tab .deck-item:hover .d3 {
  opacity: 1;
  visibility: visible;
}
.side-drawer-main .empty-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
}
.side-drawer-main .empty-box h6 {
  font-family: "avenirnextdemibold";
  font-size: 1.25rem;
  margin-bottom: 10px;
}
.side-drawer-main .empty-box p {
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  margin-bottom: 15px;
}
.side-drawer-main .empty-box span {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.side-drawer-main .empty-box span i {
  font-size: 1.125rem;
  margin-top: -2px;
  margin-right: 5px;
}
.side-drawer-main .r-error .error-field {
  color: #e60000;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
}

.email-compose-drawer {
  right: -650px;
  width: 650px;
}
.email-compose-drawer
  .custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn,
.email-compose-drawer
  .custom-basic-editor
  .thread-editor-controls
  .email-template-btn {
  display: inline-block;
}
.email-compose-drawer .side-drawer-title {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 25px 0 15px 0;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.email-compose-drawer .side-drawer-title h3 {
  padding-left: 20px;
}
.email-compose-drawer .side-drawer-title span {
  padding-right: 30px;
}
.email-compose-drawer .side-drawer-title .email-recipient {
  width: 100%;
  text-align: left;
  padding: 25px 20px 0 20px;
}
.email-compose-drawer .side-drawer-title .email-recipient p {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-bottom: -2px;
}
.email-compose-drawer .side-drawer-title .email-recipient p span {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  padding-right: 0;
  display: inline-block !important;
}
.email-compose-drawer .side-drawer-title .email-recipient span.label {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  padding-right: 0;
  cursor: default;
  margin-left: 23px;
}
.email-compose-drawer .side-drawer-tabs .content-tabs-box {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.email-compose-drawer .side-drawer-tabs .content-tabs-box .email-subject input {
  border-radius: 0;
  border: none;
}
.email-compose-drawer .side-drawer-tabs .content-tabs-box .email-subject input {
  height: 45px;
}
.email-compose-drawer .side-drawer-tabs .content-tabs-box .email-text {
  height: calc(100% - 20px);
}
.email-compose-drawer .side-drawer-tabs .content-tabs-box .custom-basic-editor {
  border-bottom: none;
}
.email-compose-drawer
  .side-drawer-tabs
  .content-tabs-box
  .custom-basic-editor
  .thread-editor-controls {
  padding: 15px 20px;
}
.email-compose-drawer
  .side-drawer-tabs
  .content-tabs-box
  .custom-basic-editor
  .thread-editor-field
  .ProseMirror {
  padding: 30px 20px;
}

.parameter-drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -250px;
  background-color: #f9f9f9;
  height: 100%;
  width: 250px;
  z-index: 999999;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.parameter-drawer.active {
  right: 0;
}
.parameter-drawer .parameter-wrapper .p-drawer-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 22px 20px;
}
.parameter-drawer .parameter-wrapper .p-drawer-title p {
  color: #212121;
  font-family: "avenirnextdemibold";
  line-height: 42px;
  font-size: 1.125rem;
  margin-bottom: 0;
}
.parameter-drawer .parameter-wrapper .p-drawer-title span {
  cursor: pointer;
}
.parameter-drawer .parameter-wrapper .p-drawer-list {
  padding: 0 20px;
}
.parameter-drawer .parameter-wrapper .p-drawer-list .p-search-box {
  position: relative;
  margin-bottom: 20px;
}
.parameter-drawer .parameter-wrapper .p-drawer-list .p-search-box input {
  padding-left: 35px;
}
.parameter-drawer .parameter-wrapper .p-drawer-list .p-search-box i {
  color: #212121;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 10px;
  z-index: 1;
  font-size: 1.125rem;
  cursor: pointer;
}
.parameter-drawer .parameter-wrapper .p-drawer-list .p-search-box .close-btn {
  position: absolute;
  right: 10px;
  left: unset;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.parameter-drawer .parameter-wrapper .p-drawer-list .p-input-field {
  overflow-y: auto;
  height: calc(100vh - 155px);
}
.parameter-drawer .parameter-wrapper .p-drawer-list .p-input-field button {
  background-color: #fff;
  color: #212121;
  border: 1px solid rgba(33, 33, 33, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  margin-bottom: 10px;
}
.parameter-drawer .parameter-wrapper .p-drawer-list .p-input-field button i {
  font-size: 10px;
}
.parameter-drawer
  .parameter-wrapper
  .p-drawer-list
  .p-input-field
  button.active {
  background-color: #ebebeb;
}

.manage-deck-drawer .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 11;
  opacity: 0;
  visibility: hidden;
}
.manage-deck-drawer.decks-active .overlay {
  opacity: 1;
  visibility: visible;
}
.manage-deck-drawer.decks-active .manage-deck-tab .deck-item .d3 button {
  opacity: 0;
  visibility: hidden;
}

.handle-deck-drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -300px;
  background-color: #f9f9f9;
  height: 100%;
  width: 300px;
  z-index: 999999;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.handle-deck-drawer.active {
  right: 0;
}
.handle-deck-drawer .handle-deck-wrapper .drawer-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 22px 20px;
}
.handle-deck-drawer .handle-deck-wrapper .drawer-title p {
  color: #212121;
  font-family: "avenirnextdemibold";
  line-height: 42px;
  font-size: 1.125rem;
  margin-bottom: 0;
}
.handle-deck-drawer .handle-deck-wrapper .drawer-title span {
  cursor: pointer;
}
.handle-deck-drawer .handle-deck-wrapper input {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.handle-deck-drawer .handle-deck-wrapper .drawer-list {
  padding: 0 20px;
}
.handle-deck-drawer .handle-deck-wrapper .drawer-list .button-group {
  text-align: right;
  margin-top: 20px;
}
.handle-deck-drawer .handle-deck-wrapper .drawer-list .button-group span {
  cursor: pointer;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-left: 30px;
  display: inline-block !important;
}
.handle-deck-drawer
  .handle-deck-wrapper
  .drawer-list
  .button-group
  span.delete-btn {
  color: #e60000;
}
.handle-deck-drawer
  .handle-deck-wrapper
  .drawer-list
  .button-group
  span.save-btn {
  color: #0a87cf;
}

.email-template-drawer .side-drawer-title .et-label {
  display: inline-block;
  background-color: #eee;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 0.75rem;
  margin-top: 6px;
  cursor: default;
}
.email-template-drawer .side-drawer-controls {
  background-color: #f9f9f9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 30px;
}
.email-template-drawer .side-drawer-controls span {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  margin-right: 25px;
}
.email-template-drawer .side-drawer-controls span i {
  margin-right: 5px;
}
.email-template-drawer .side-drawer-tabs .content-tabs-box {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  height: calc(100vh - 90px);
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-subject {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-subject
  span {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  white-space: nowrap;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-subject
  input {
  border-radius: 0;
  border: none;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-subject
  input {
  height: 50px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 30px;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  .label,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  .label,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  .label {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-right: 6px;
  white-space: nowrap;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  span.title,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  span.title,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  span.title {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  span.title
  i,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  span.title
  i,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  span.title
  i {
  font-size: 20px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  .et-dropdown,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  .et-dropdown,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  .et-dropdown {
  position: relative;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  .et-dropdown
  .list,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  .et-dropdown
  .list,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  .et-dropdown
  .list {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  position: absolute;
  z-index: 2;
  padding: 10px 15px;
  min-width: 250px;
  display: none;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  .et-dropdown
  .list
  div.checkbox-group,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  .et-dropdown
  .list
  div.checkbox-group,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  .et-dropdown
  .list
  div.checkbox-group {
  margin-bottom: 8px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  .et-dropdown
  .list
  div.checkbox-group:last-child,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  .et-dropdown
  .list
  div.checkbox-group:last-child,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  .et-dropdown
  .list
  div.checkbox-group:last-child {
  margin-bottom: 0;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  .et-dropdown
  .list
  span,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  .et-dropdown
  .list
  span,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  .et-dropdown
  .list
  span {
  cursor: pointer;
  display: block;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  margin-bottom: 12px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  .et-dropdown
  .list
  span:last-of-type,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  .et-dropdown
  .list
  span:last-of-type,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  .et-dropdown
  .list
  span:last-of-type {
  margin-bottom: 0;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  .et-dropdown.active
  .list,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  .et-dropdown.active
  .list,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  .et-dropdown.active
  .list {
  display: block;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-category
  input,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-service
  input,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-other-details
  .email-others
  input {
  width: auto;
  border: none;
  border-radius: 0;
  padding: 0;
  height: 100%;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-text {
  height: calc(100vh - 245px);
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .custom-basic-editor {
  border-bottom: none;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .custom-basic-editor
  .thread-editor-controls {
  padding: 15px 30px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .custom-basic-editor
  .thread-editor-field {
  height: calc(100vh - 305px);
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .custom-basic-editor
  .thread-editor-field
  .ProseMirror {
  padding: 30px 30px;
  height: 100%;
  overflow: auto;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-signature {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding: 15px 20px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-signature
  p {
  font-size: 0.875rem;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-signature
  span {
  color: rgba(33, 33, 33, 0.5);
  font-size: 0.75rem;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .create-email-template
  .email-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.email-template-drawer .side-drawer-tabs .content-tabs-box .email-btn {
  text-align: right;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  padding: 15px 20px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-btn
  .drawer-btn {
  cursor: pointer;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-left: 30px;
  display: inline-block !important;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-btn
  .cancel-btn {
  color: #212121;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-btn
  .save-btn {
  color: #0a87cf;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-btn
  .delete-btn {
  color: #e60000;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-btn
  .error-field {
  color: #e60000;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
}
.email-template-drawer .side-drawer-tabs .content-tabs-box .empty-box.active {
  display: none;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list {
  padding: 20px 30px 30px 30px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .email-btn {
  margin-left: -30px;
  margin-right: -30px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list.active {
  display: none;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 25px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box {
  width: 250px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .search-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  color: rgba(33, 33, 33, 0.5);
  border-radius: 10px;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  padding: 10px 15px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .search-btn
  i {
  font-size: 1.25rem;
  margin-right: 6px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .input-search {
  position: relative;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .input-search
  input {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 35px;
  padding-right: 65px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .input-search
  .icon-search,
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .input-search
  .controls {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .input-search
  i {
  color: rgba(33, 33, 33, 0.5);
  font-size: 1.25rem;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .input-search
  .icon-search {
  left: 10px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .input-search
  .controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 15px;
  opacity: 0;
  visibility: hidden;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .input-search
  .controls
  i {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  font-size: 0.875rem;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .input-search
  .controls
  i.icon-close {
  background-color: rgba(10, 135, 207, 0.1);
  color: #0a87cf;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box
  .input-search
  .controls
  i.icon-arrow-right {
  background-color: #0a87cf;
  color: #fff;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .search-box.active
  .input-search
  .controls {
  opacity: 1;
  visibility: visible;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .plain-btn {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .top-part
  .plain-btn
  i {
  font-size: 1.125rem;
  margin-right: 5px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part {
  height: calc(100vh - 275px);
  overflow: auto;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-deleteall {
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-deleteall
  label {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-item
  .e1
  .checkbox-group
  input {
  position: relative;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-item
  .e2 {
  width: 85%;
  padding-left: 15px;
  padding-right: 15px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-item
  .e2
  p.template-name {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-bottom: -5px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-item
  .e2
  span.template-update {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-item
  .e3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  transition: 0.1s ease;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-item
  .e3
  button {
  padding: 4px 6px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-left: 4px;
  margin-right: 4px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-item
  .e3
  button
  i {
  font-size: 18px;
  margin-right: 0;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-item
  .e3
  button:first-of-type
  i {
  font-size: 17px;
}
.email-template-drawer
  .side-drawer-tabs
  .content-tabs-box
  .email-template-list
  .bottom-part
  .emailtemplate-item:hover
  .e3 {
  opacity: 1;
  visibility: visible;
}

.form-group-box .form-group-title {
  margin-top: 25px;
  margin-bottom: 10px;
}
.form-group-box .form-group-title:first-of-type {
  margin-top: 0;
}
.form-group-box .form-group-title label {
  text-transform: uppercase;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-bottom: 0;
}
.form-group-box .form-group-title.w-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-group-box .form-group-title.w-checkbox .checkbox-group {
  margin-left: 15px;
}
.form-group-box .input-group-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 15px;
}
.form-group-box .input-group-box:last-of-type {
  margin-bottom: 0;
}
.form-group-box .input-group-box.full-box- .field-box {
  width: 100%;
}
.form-group-box .input-group-box.half-box- .field-box {
  width: 100%;
}

@media (min-width: 768px) {
  .form-group-box .input-group-box.half-box- .field-box {
    width: 50%;
  }
}
.form-group-box .input-group-box.three-box- .field-box {
  width: 33.3333333333%;
}
.form-group-box .input-group-box .field-box {
  padding-left: 10px;
  padding-right: 10px;
  width: 33.3333333333%;
  margin-top: 15px;
}
.form-group-box .input-group-box .field-box label {
  color: rgba(33, 33, 33, 0.5);
  text-transform: uppercase;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  margin-bottom: 6px;
}
.form-group-box .input-group-box .field-box label small {
  color: #e60000;
}
.form-group-box .input-group-box .field-box.add-rates-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.form-group-box .input-group-box .field-box.add-rates-btn button {
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  color: #212121;
  font-size: 12px;
  padding: 5px 10px;
  text-decoration: none;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.form-group-box .input-group-box .field-box.add-rates-btn button i {
  margin-right: 7px !important;
  font-size: 11px;
}
.form-group-box .input-group-box .field-box.add-rates-btn button:hover {
  background-color: #ededed;
}
.form-group-box .input-group-box .field-box textarea {
  height: auto;
}
.form-group-box .input-group-box p.error-field,
.form-group-box .input-group-box.error-field span {
  color: #e60000;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  margin-top: 8px;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
.c-input,
textarea,
select {
  background-color: #fff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  color: #212121;
  border-radius: 10px;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  padding: 10px 20px;
  width: 100%;
}

select.c-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  background-size: 25px;
  padding-right: 30px;
  /* Adjust the padding as needed */
}

/* For Firefox */
select.c-select::-ms-expand {
  display: none;
}

/* For Internet Explorer 10+ */
select.c-select::-ms-value {
  display: none;
}

::-webkit-input-placeholder {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}

::-moz-placeholder {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}

:-ms-input-placeholder {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}

::-ms-input-placeholder {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}

::placeholder {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}

.form-errors p {
  color: #e60000;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  margin-top: 8px;
}

.form-group {
  margin-bottom: 25px;
}
.form-group label {
  font-family: "avenirnextdemibold";
  margin-bottom: 6px;
}
.form-group label.l-main {
  color: #212121;
  font-size: 0.875rem;
}
.form-group label.l-sub {
  color: rgba(33, 33, 33, 0.5);
  font-size: 0.75rem;
}

.checkbox-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.checkbox-group input {
  -webkit-appearance: none;
  width: 13px;
  height: 13px;
  border: 1px solid #212121;
  border-radius: 0;
  vertical-align: sub;
  position: absolute;
  outline: none;
  cursor: pointer;
}
.checkbox-group input:checked {
  background-color: #0a87cf;
  border-color: #0a87cf;
}
.checkbox-group input:checked + label {
  color: #0a87cf;
}
.checkbox-group input:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==");
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
}
.checkbox-group label {
  cursor: pointer;
  position: relative;
  top: 0;
  color: #212121;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  margin-bottom: 0 !important;
  margin-left: 20px;
}

input[disabled] {
  background: rgba(33, 33, 33, 0.2);
  color: rgba(33, 33, 33, 0.5);
}
input[disabled]:active,
input[disabled]:hover,
input[disabled]:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-dropdown-multiselect {
  position: relative;
}
.custom-dropdown-multiselect .multiselect-btn {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  color: #212121;
  border-radius: 10px;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  padding: 10px 20px;
  width: 100%;
  height: 43px;
  cursor: pointer;
}
.custom-dropdown-multiselect .multiselect-btn span.multiselect-text {
  display: block;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 30px;
}
.custom-dropdown-multiselect .multiselect-btn i {
  content: url("/arrow-down.svg");
  width: 25px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.custom-dropdown-multiselect .multiselect-btn i.active {
  content: url("/close-icon.svg");
}
.custom-dropdown-multiselect .multiselect-dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  border-radius: 10px;
  padding: 15px 20px;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  max-height: 170px;
  overflow: scroll;
}
.custom-dropdown-multiselect .multiselect-dropdown.active {
  opacity: 1;
  visibility: visible;
}
.custom-dropdown-multiselect .multiselect-dropdown .multiselect-list span {
  font-size: 0.875rem;
  cursor: pointer;
}
.custom-dropdown-multiselect
  .multiselect-dropdown
  .multiselect-list
  .checkbox-group {
  cursor: pointer;
  margin-bottom: 8px;
}
.custom-dropdown-multiselect
  .multiselect-dropdown
  .multiselect-list
  .checkbox-group:last-of-type {
  margin-bottom: 0;
}
.custom-dropdown-multiselect
  .multiselect-dropdown
  .multiselect-list
  .checkbox-group:hover {
  background-color: rgba(176, 176, 176, 0.15);
}

.c-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #0a87cf;
  color: #fff;
  border-radius: 10px;
  font-family: "avenirnextdemibold";
  font-size: 12px;
  padding: 8px 10px;
}
.c-btn i {
  font-size: 18px;
  margin-top: -1.5px;
  margin-right: 5px;
}
.c-btn i.icon-envelope-o {
  font-size: 16px;
  margin-top: -2.5px;
}
.c-btn svg {
  margin-right: 6px;
}
.c-btn.c-btn-light {
  background-color: rgba(10, 135, 207, 0.1);
  color: #0a87cf;
}
.c-btn.c-btn-bordered {
  background-color: transparent;
  color: #0a87cf;
  border: 1px solid #0a87cf;
}
.c-btn.c-btn-red {
  background-color: #e60000;
}
.c-btn.c-btn-lg {
  padding: 13px 25px;
}
.c-btn.c-btn-sm {
  padding: 4px 8px;
  font-size: 0.75rem;
}
.c-btn.c-btn-sm i {
  font-size: 0.875rem;
}
.c-btn.c-btn-disabled {
  cursor: default;
  pointer-events: none !important;
  background-color: rgba(33, 33, 33, 0.2);
  color: rgba(33, 33, 33, 0.5);
}

.hidden-btn {
  opacity: 0;
  visibility: hidden;
}

button.copy-btn {
  position: relative;
  background-color: rgba(10, 135, 207, 0.1);
  color: #0a87cf;
  border-radius: 4px;
  margin-left: 5px;
  padding: 2px 4px;
}
button.copy-btn svg {
  fill: #0a87cf;
}
button.copy-btn .toast-text {
  position: absolute;
  left: 0;
  bottom: -27px;
  background-color: #0a87cf;
  color: #fff;
  border-radius: 4px;
  padding: 2px 6px;
  white-space: unset !important;
  word-break: unset !important;
}
button.copy-btn .toast-text::before {
  content: "";
  position: absolute;
  top: -5px;
  border-right: solid 5px transparent;
  border-left: solid 5px transparent;
  border-bottom: solid 5px #0a87cf;
}

.main-table-section {
  padding: 0 30px 10px 30px;
}
.main-table-section .table-view {
  position: relative;
}
.main-table-section .table-view .table-scroll {
  background-color: #fff;
  border-radius: 10px;
  overflow: auto;
  height: calc(100vh - 205px);
}
.main-table-section .table-view .table-scroll.active {
  margin-top: 15px;
  height: calc(100vh - 300px);
}
.main-table-section .table-view .table-scroll::-webkit-scrollbar {
  display: block;
}
.main-table-section .table-view .table-scroll::-webkit-scrollbar-track {
  background-color: #fff;
}
.main-table-section .table-view .table-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #fff;
}
.main-table-section .table-view .table-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(33, 33, 33, 0.2);
  border-radius: 2px;
}
.main-table-section .table-view table {
  table-layout: fixed;
  width: 100%;
}
.main-table-section .table-view table .checkbox-group {
  margin-right: 5px;
  margin-top: -1px;
}
.main-table-section .table-view table .checkbox-group input {
  border-color: rgba(33, 33, 33, 0.5);
  border-radius: 2px;
}
.main-table-section .table-view table thead {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
}
.main-table-section .table-view table thead th {
  vertical-align: middle;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  padding: 15px 10px 10px 10px;
}
.main-table-section .table-view table thead th .th-w-sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.main-table-section .table-view table thead th .th-w-sort .sort-icons {
  margin-top: 3px;
  margin-left: 12px;
}
.main-table-section .table-view table thead th .th-w-sort .sort-icons i {
  position: relative;
  display: block;
  cursor: pointer;
  margin: 0;
  color: #0a87cf;
  font-size: 10px;
  width: 5px;
  height: 5px;
  line-height: 0;
}
.main-table-section .table-view table thead th .th-w-sort .sort-icons i.active {
  opacity: 0.4;
  pointer-events: none;
}
.main-table-section .table-view table thead th span {
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  line-height: 1.3;
}
.main-table-section .table-view table tbody tr {
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
}
.main-table-section .table-view table tbody tr:hover,
.main-table-section .table-view table tbody tr.active {
  background-color: #f3f9fd;
}
.main-table-section .table-view table tbody tr:hover td,
.main-table-section .table-view table tbody tr.active td {
  background-color: #f3f9fd !important;
}
.main-table-section .table-view table tbody td {
  vertical-align: middle;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  padding: 10px;
}
.main-table-section .table-view table tbody td span {
  word-break: break-word;
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  line-height: 1.3;
}
.main-table-section .table-view table tbody td span.b-color {
  color: #212121;
}
.main-table-section .table-view table tbody td span.box-text {
  float: left;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 2px 4px;
  margin-top: 4px;
  margin-right: 4px;
}
.main-table-section .table-view table tbody td span.text-btn {
  font-family: "avenirnextdemibold";
  color: #0a87cf;
  cursor: pointer;
}
.main-table-section .table-view table tbody .name-td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.main-table-section .table-view table tbody .name-td .checkbox-group {
  margin-top: 9px;
}
.main-table-section .table-view table tbody .name-info {
  width: 100%;
  cursor: pointer;
}
.main-table-section .table-view table tbody .name-info .main-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.main-table-section .table-view table tbody .name-info .main-name span.name {
  color: #212121;
  font-family: "avenirnextdemibold";
}
.main-table-section
  .table-view
  table
  tbody
  .name-info
  .main-name
  .notif-box
  div {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(10, 135, 207, 0.1);
  color: #0a87cf;
  border-radius: 50%;
  font-size: 10px;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.main-table-section
  .table-view
  table
  tbody
  .name-info
  .main-name
  .notif-box
  span {
  position: absolute;
  top: -3px;
  right: -3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #e60000;
  color: #fff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-family: "avenirnextdemibold";
  font-size: 7px;
}
.main-table-section
  .table-view
  table
  tbody
  .name-info
  .main-name
  .notif-box
  span.empty {
  background-color: #0a87cf;
}
.main-table-section
  .table-view
  table
  tbody
  .name-info
  .others-info
  span.others-text {
  float: left;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 2px 4px;
  margin-top: 4px;
  margin-right: 4px;
  font-size: 0.625rem;
}
.main-table-section
  .table-view
  table
  tbody
  .name-info
  .others-info
  span.others-text:last-of-type {
  margin-right: 0;
}
.main-table-section .table-view table tbody .status--box span {
  display: block;
}
.main-table-section .table-view table tbody .status--box .status-text {
  display: inline-block;
  font-family: "avenirnextdemibold";
  border-radius: 10px;
  padding: 4px 12px;
  margin-bottom: 5px;
}
.main-table-section .table-view table tbody .time-text {
  color: rgba(33, 33, 33, 0.5);
  font-size: 0.625rem;
}
.main-table-section .table-view table tbody .text-ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.main-table-section .table-view .table-empty-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.main-table-section .table-view .table-empty-box div {
  text-align: center;
}
.main-table-section .table-view .table-empty-box h3 {
  font-family: "avenirnextdemibold";
  font-size: 1.25rem;
  margin-bottom: 15px;
}
.main-table-section .table-view .table-empty-box p {
  font-size: 0.75rem;
  margin-bottom: 15px;
}
.main-table-section .table-view .table-empty-box span {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.main-table-section .table-view .table-empty-box span i {
  font-size: 1.125rem;
  margin-top: -2px;
  margin-right: 5px;
}
.main-table-section .table-view.table-leads table {
  width: 140%;
}
.main-table-section .table-view.table-leads table thead th:first-of-type,
.main-table-section .table-view.table-leads table tbody td:first-of-type {
  background-color: #fff;
  position: sticky;
  left: 0;
  z-index: 1;
  padding-left: 15px;
  border-right: 1px solid #fff;
}
.main-table-section .table-view.table-leads table thead th:first-of-type::after,
.main-table-section
  .table-view.table-leads
  table
  tbody
  td:first-of-type::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: #f9f9f9;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-1
  .status-text {
  background: rgba(0, 179, 254, 0.06);
  color: #00b3fe;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-2
  .status-text {
  background: rgba(22, 117, 224, 0.06);
  color: #1675e0;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-3
  .status-text {
  background: rgba(80, 100, 255, 0.06);
  color: #5064ff;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-4
  .status-text {
  background: rgba(72, 163, 158, 0.06);
  color: #48a39e;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-5
  .status-text {
  background: rgba(136, 150, 247, 0.06);
  color: #8896f7;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-6
  .status-text {
  background: rgba(157, 20, 198, 0.06);
  color: #9d14c6;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-7
  .status-text {
  background: rgba(24, 150, 136, 0.06);
  color: #189688;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-8
  .status-text {
  background: rgba(96, 198, 8, 0.06);
  color: #60c608;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-9
  .status-text {
  background: rgba(203, 152, 13, 0.06);
  color: #cb980d;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-10
  .status-text {
  background: rgba(219, 0, 0, 0.06);
  color: #db0000;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-11
  .status-text {
  background: rgba(251, 82, 0, 0.06);
  color: #fb5200;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-12
  .status-text {
  background: rgba(248, 176, 3, 0.06);
  color: #f8b003;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-13
  .status-text {
  background: rgba(4, 155, 167, 0.06);
  color: #049ba7;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-14
  .status-text {
  background: rgba(232, 72, 61, 0.06);
  color: #e8483d;
}
.main-table-section
  .table-view.table-leads
  table
  tbody
  .status--box.g-status--color-15
  .status-text {
  background: rgba(167, 84, 4, 0.06);
  color: #a75404;
}
.main-table-section .table-view.table-influencers table {
  width: 120%;
}
.main-table-section .table-view.table-influencers table thead th:nth-child(1),
.main-table-section .table-view.table-influencers table thead th:nth-child(2),
.main-table-section .table-view.table-influencers table thead th:nth-child(3),
.main-table-section .table-view.table-influencers table tbody td:nth-child(1),
.main-table-section .table-view.table-influencers table tbody td:nth-child(2),
.main-table-section .table-view.table-influencers table tbody td:nth-child(3) {
  background-color: #fff;
  position: sticky;
  z-index: 1;
  padding-left: 15px;
}
.main-table-section .table-view.table-influencers table thead th:nth-child(1),
.main-table-section .table-view.table-influencers table tbody td:nth-child(1) {
  left: 0;
}
.main-table-section .table-view.table-influencers table th:nth-child(2),
.main-table-section .table-view.table-influencers table td:nth-child(2) {
  left: calc((120% + 105px) * 0.19);
}
.main-table-section .table-view.table-influencers table th:nth-child(3),
.main-table-section .table-view.table-influencers table td:nth-child(3) {
  left: calc((120% + 105px) * 0.29);
  border-right: 1px solid #fff;
}
.main-table-section .table-view.table-influencers table th:nth-child(3)::after,
.main-table-section .table-view.table-influencers table td:nth-child(3)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: #f9f9f9;
}
.main-table-section .table-view.table-influencers table tbody .platform-box {
  width: 100%;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-summary
  a {
  margin-top: 4px;
  margin-right: 4px;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-icon
  img {
  width: 25px;
  height: 25px;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-icon
  .info-text {
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  position: absolute;
  top: -25px;
  left: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  font-size: 0.75rem;
  padding: 2px 8px;
  z-index: 15;
  word-break: unset;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-icon:hover
  > .info-text {
  opacity: 1;
  visibility: visible;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-summary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-summary
  div:first-child {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-summary
  div:last-child {
  width: 40%;
  text-align: right;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-summary
  div:last-child
  button {
  background-color: transparent;
  border: none;
  color: rgba(33, 33, 33, 0.5);
  padding: 0;
  font-size: 0.625rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-summary
  div:last-child
  button
  span {
  text-align: right;
  font-weight: inherit;
  font-size: inherit;
  display: block;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-summary
  div:last-child
  button
  i {
  margin-left: 5px;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-info {
  overflow: hidden;
  margin-left: -5px;
  margin-right: -5px;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-info
  .platform-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #eee;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-info
  .platform-group:first-child {
  margin-top: 10px;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-info
  .platform-group
  div {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 5px;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-info
  .platform-group
  div
  a {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-info
  .platform-group
  div
  a
  img {
  margin-right: 5px;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-info
  .platform-group
  div
  span {
  display: block;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-info
  .platform-group
  div
  span.total-number {
  font-family: "avenirnextdemibold";
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-info
  .platform-group
  div
  span.time-text {
  font-size: 0.625rem;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .platform-box
  .platform-info
  .platform-group
  div:last-child {
  text-align: right;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .status--box.g-status--color-1
  .status-text {
  background: rgba(40, 174, 96, 0.06);
  color: #28ae60;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .status--box.g-status--color-2
  .status-text {
  background: rgba(255, 148, 96, 0.06);
  color: #ff9460;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .status--box.g-status--color-3
  .status-text {
  background: rgba(221, 52, 68, 0.06);
  color: #dd3444;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .status--box.g-status--color-4
  .status-text {
  background: rgba(22, 117, 224, 0.06);
  color: #1675e0;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .status--box.g-status--color-5
  .status-text {
  background: rgba(4, 155, 167, 0.06);
  color: #049ba7;
}
.main-table-section
  .table-view.table-influencers
  table
  tbody
  .status--box.g-status--color-6
  .status-text {
  background: rgba(203, 152, 13, 0.06);
  color: #cb980d;
}
.main-table-section .alert {
  position: absolute;
  top: 50px;
  bottom: unset;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.table-actions-box {
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 24px;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 10;
}
.table-actions-box.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.table-actions-box .b1 p {
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 1.25rem;
}
.table-actions-box .b2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
}
.table-actions-box .b2 div.cl {
  padding-left: 2px;
  padding-right: 2px;
}
.table-actions-box .b2 div.cl span {
  font-size: 13px;
}
.table-actions-box .b2 div.cl div.action-box {
  position: relative;
  cursor: pointer;
  padding: 5px 8px;
  border-radius: 4px;
}
.table-actions-box .b2 div.cl div.action-box span.as,
.table-actions-box .b2 div.cl div.action-box i.ai {
  text-align: center;
  display: block;
}
.table-actions-box .b2 div.cl div.action-box i.ai {
  margin-bottom: 3px;
}
.table-actions-box .b2 div.cl div.action-box:hover,
.table-actions-box .b2 div.cl div.action-box.active {
  background-color: rgba(10, 135, 207, 0.1);
}
.table-actions-box .b2 div.cl div.action-box:hover span.as,
.table-actions-box .b2 div.cl div.action-box:hover i.ai,
.table-actions-box .b2 div.cl div.action-box.active span.as,
.table-actions-box .b2 div.cl div.action-box.active i.ai {
  color: #0a87cf;
}
.table-actions-box .b2 div.cl div.action-box:hover span.as,
.table-actions-box .b2 div.cl div.action-box.active span.as {
  font-family: "avenirnextdemibold";
}
.table-actions-box .b2 div.cl div.action-box .action-dropdown {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  position: absolute;
  bottom: 80px;
  right: 0;
  min-width: 220px;
  z-index: 2;
  display: none;
}
.table-actions-box .b2 div.cl div.action-box .action-dropdown.active {
  display: block;
}
.table-actions-box .b2 div.cl div.action-box .action-dropdown .triangle {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -30px;
  right: 20px;
  overflow: hidden;
  -webkit-box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5);
  box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5);
}
.table-actions-box .b2 div.cl div.action-box .action-dropdown .triangle::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -8px;
  left: 10px;
  -webkit-box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.8);
  box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.8);
}
.table-actions-box .b2 div.cl div.action-box .action-dropdown .dropdown-title,
.table-actions-box .b2 div.cl div.action-box .action-dropdown .dropdown-list {
  padding: 10px 15px;
}
.table-actions-box .b2 div.cl div.action-box .action-dropdown .dropdown-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-title
  p {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-title
  p
  i {
  margin-right: 6px;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-title
  i {
  font-size: 1rem;
  cursor: pointer;
  margin-right: -3px;
}
.table-actions-box .b2 div.cl div.action-box .action-dropdown .dropdown-list {
  overflow: auto;
  max-height: 250px;
  border-top: 1px solid #f5f5f5;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list
  span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  margin-bottom: 12px;
  text-align: left;
  cursor: pointer;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list
  span:last-of-type {
  margin-bottom: 0;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.priority-list
  i {
  font-size: 1.125rem;
  margin-right: 4px;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.priority-list
  span.font-color-high {
  color: #dd3444;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.priority-list
  span.font-color-medium {
  color: #ff9460;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.priority-list
  span.font-color-low {
  color: #1675e0;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group {
  margin-bottom: 10px;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name {
  font-family: "avenirnextdemibold";
  margin-bottom: 5px;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name.filter-color-1 {
  color: #00b3fe;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name.filter-color-2 {
  color: #1675e0;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name.filter-color-3 {
  color: #5064ff;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name.filter-color-4 {
  color: #48a39e;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name.filter-color-5 {
  color: #8896f7;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name.filter-color-6 {
  color: #9d14c6;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name.filter-color-7 {
  color: #189688;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name.filter-color-8 {
  color: #60c608;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name.filter-color-9 {
  color: #cb980d;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group
  .filter-name.filter-color-10 {
  color: #db0000;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-group:last-of-type {
  margin-bottom: 0;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-name-list {
  padding-left: 10px;
  margin-bottom: 5px;
}
.table-actions-box
  .b2
  div.cl
  div.action-box
  .action-dropdown
  .dropdown-list.status-list
  .filter-name-list:last-of-type {
  margin-bottom: 0;
}
.table-actions-box .b3 span {
  cursor: pointer;
  border: 1px solid rgba(33, 33, 33, 0.5);
  border-radius: 2px;
  color: rgba(33, 33, 33, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.table-pagination-box {
  background-color: #f9f9f9;
  padding-top: 10px;
}
.table-pagination-box .pagination-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.table-pagination-box .pagination-box .influencer-counts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table-pagination-box .pagination-box .influencer-counts span {
  display: inline-block;
  white-space: nowrap;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
}
.table-pagination-box .pagination-box .influencer-counts select {
  cursor: pointer;
  background-color: #fff;
  color: #212121;
  background-image: -o-linear-gradient(
      45deg,
      transparent 50%,
      rgba(33, 33, 33, 0.3019607843) 0
    ),
    -o-linear-gradient(315deg, rgba(33, 33, 33, 0.3019607843) 50%, transparent 0);
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      rgba(33, 33, 33, 0.3019607843) 0
    ),
    linear-gradient(135deg, rgba(33, 33, 33, 0.3019607843) 50%, transparent 0);
  background-position: calc(80% - 3px) calc(0.5em + 9px),
    calc(80% + 2px) calc(0.5em + 9px), calc(100% - 1.5em) 0.1em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border: 1px solid #fff;
  border-radius: 10px;
  height: 35px;
  line-height: 25px;
  padding: 0 10px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  width: 55px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.table-pagination-box .pagination-box .go-to-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}
.table-pagination-box .pagination-box .go-to-page span {
  margin-right: 5px;
}
.table-pagination-box .pagination-box .go-to-page input {
  background-color: transparent;
  border-color: #e8e8e8;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  width: 55px;
  height: 35px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 10px;
}
.table-pagination-box
  .pagination-box
  .go-to-page
  input[type="number"]::-webkit-outer-spin-button,
.table-pagination-box
  .pagination-box
  .go-to-page
  input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.table-pagination-box .pagination-box ul {
  list-style-type: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 0 5px;
}
.table-pagination-box .pagination-box ul a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  color: #212121;
  cursor: not-allowed;
  opacity: 0.3;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  margin-left: 8px;
}
.table-pagination-box .pagination-box ul a i {
  font-size: 1.25rem;
}
.table-pagination-box .pagination-box ul a.active {
  cursor: pointer;
  opacity: 1;
}

.manage-email-table-section .table-view .table-scroll {
  height: calc(100vh - 290px);
}
.manage-email-table-section .table-view table tbody tr {
  position: relative;
}
.manage-email-table-section .table-view table tbody tr .name-info {
  cursor: default;
}
.manage-email-table-section .table-view table tbody tr .et-controls {
  background-color: #f3f9fd;
  position: absolute;
  right: 0;
  padding-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  opacity: 0;
  visibility: hidden;
}
.manage-email-table-section .table-view table tbody tr .et-controls span {
  cursor: pointer;
  background-color: rgba(10, 135, 207, 0.1);
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  font-size: 16px;
}
.manage-email-table-section .table-view table tbody tr .et-controls span i {
  color: #0a87cf;
}
.manage-email-table-section
  .table-view
  table
  tbody
  tr
  .et-controls
  span.share.off {
  background-color: #0a87cf;
}
.manage-email-table-section
  .table-view
  table
  tbody
  tr
  .et-controls
  span.share.off
  i {
  color: #fff;
}
.manage-email-table-section .table-view table tbody tr:hover .et-controls {
  opacity: 1;
  visibility: visible;
}

.avatar-box span {
  background-color: #23293f;
  color: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 31px;
  height: 31px;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.avatar-box span img {
  position: absolute;
  min-height: 110%;
  min-width: 110%;
  object-fit: cover;
}

.avatar-box.avatar-color-1 span {
  background-color: #00b3fe;
}
.avatar-box.avatar-color-2 span {
  background-color: #1675e0;
}
.avatar-box.avatar-color-3 span {
  background-color: #5064ff;
}
.avatar-box.avatar-color-4 span {
  background-color: #48a39e;
}
.avatar-box.avatar-color-5 span {
  background-color: #8896f7;
}

.download-box-indicator {
  position: relative;
  z-index: 1;
  background-color: #2a3453;
  padding: 0 20px;
  overflow: hidden;
}
.download-box-indicator .download-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
  height: 0;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.download-box-indicator .download-data span {
  color: #fff;
}
.download-box-indicator.active .download-data {
  height: 45px;
}
.download-box-indicator .spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #43b3fe;
  border-left: 2px solid #f4f6f6;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
  margin-left: 10px;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-sm {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
}
.loader-sm div {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  margin: 1px;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-animation: loader-sm 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: loader-sm 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.loader-sm div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}
.loader-sm div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.loader-sm div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}
@-webkit-keyframes loader-sm {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-sm {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.d-none {
  display: none;
}

.confirmation-modal-container .modal-dialog {
  max-width: 450px;
}
.confirmation-modal-container .modal-dialog .modal-content {
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.confirmation-modal-container .modal-body {
  text-align: center;
  padding: 40px 30px;
}
.confirmation-modal-container .modal-body h3,
.confirmation-modal-container .modal-body p {
  font-family: "avenirnextdemibold";
}
.confirmation-modal-container .modal-body svg {
  color: #e60000;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}
.confirmation-modal-container .modal-body h3 {
  color: #212121;
  font-size: 1.25rem;
  margin-bottom: 5px;
}
.confirmation-modal-container .modal-body p {
  color: rgba(33, 33, 33, 0.5);
  font-size: 0.875rem;
  padding-left: 50px;
  padding-right: 50px;
}
.confirmation-modal-container .modal-body p span {
  color: #212121;
}
.confirmation-modal-container .modal-body a,
.confirmation-modal-container .modal-body span {
  word-break: break-word;
}
.confirmation-modal-container .modal-body .modal-btns {
  margin-top: 20px;
}
.confirmation-modal-container .modal-body .modal-btns button {
  margin-left: 13px;
}
.confirmation-modal-container .modal-body .modal-btns button:first-of-type {
  margin-left: 0;
}
.confirmation-modal-container .modal-body .modal-btns span {
  display: block;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-top: 10px;
}
.confirmation-modal-container.modal-save-draft {
  position: absolute;
}
.confirmation-modal-container.modal-save-draft .modal-body svg {
  color: #0a87cf;
}
.confirmation-modal-container.modal-basic .modal-body .icon-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #0a87cf;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.confirmation-modal-container.modal-basic .modal-body .icon-custom i {
  font-size: 25px;
  color: #0a87cf;
}
.confirmation-modal-container.modal-basic .modal-body .field-box {
  text-align: left;
}

body.overflow-y-scroll .sidebar-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
}

.delete-side-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}
.delete-side-modal .delete-side-modal-box {
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 40px 30px;
  max-width: 450px;
}
.delete-side-modal .delete-side-modal-box h3,
.delete-side-modal .delete-side-modal-box p {
  font-family: "avenirnextdemibold";
}
.delete-side-modal .delete-side-modal-box svg {
  color: #e60000;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}
.delete-side-modal .delete-side-modal-box h3 {
  color: #212121;
  font-size: 1.25rem;
  margin-bottom: 5px;
}
.delete-side-modal .delete-side-modal-box p {
  color: rgba(33, 33, 33, 0.5);
  font-size: 0.875rem;
  padding-left: 50px;
  padding-right: 50px;
}
.delete-side-modal .delete-side-modal-box p span {
  color: #212121;
}
.delete-side-modal .delete-side-modal-box .modal-btns {
  margin-top: 20px;
}
.delete-side-modal .delete-side-modal-box .modal-btns button {
  margin-left: 13px;
}
.delete-side-modal .delete-side-modal-box .modal-btns button:first-of-type {
  margin-left: 0;
}
.delete-side-modal .delete-side-modal-box .modal-btns span {
  display: block;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-top: 10px;
}
.delete-side-modal.active {
  opacity: 1;
  visibility: visible;
}

.delete-side-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3);
}
.delete-side-modal-overlay.active {
  opacity: 1;
  visibility: visible;
}

.modal-backdrop.show {
  opacity: 0.6 !important;
}

.skeleton {
  border-radius: 4px;
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
  animation: skeleton-loading 1s linear infinite alternate;
}

@-webkit-keyframes skeleton-loading {
  0% {
    background-color: hsl(200deg, 20%, 80%);
  }
  100% {
    background-color: hsl(200deg, 20%, 95%);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200deg, 20%, 80%);
  }
  100% {
    background-color: hsl(200deg, 20%, 95%);
  }
}
.skeleton-loader {
  visibility: visible;
  opacity: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.text-loader {
  margin-top: 2px;
}
.text-loader .skeleton-box {
  height: 20px;
}
.text-loader.label {
  margin-bottom: 10px;
  width: 30%;
}

.topbar-menu .row,
.breadcrumb-menu .row {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 42px;
}

.skeleton-topbar-loader,
.skeleton-button-group {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeleton-topbar-loader {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 42px;
}
.skeleton-topbar-loader .skeleton-box {
  height: 25px;
}
.skeleton-topbar-loader .circle-skeleton {
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.13);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.13);
  border-radius: 50%;
  width: 31px;
  height: 31px;
}
.skeleton-topbar-loader .col-b {
  padding-left: 15px;
  padding-right: 15px;
}
.skeleton-topbar-loader .col-b:first-child {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.skeleton-topbar-loader .col-b:first-child .skeleton {
  margin-right: 10px;
}
.skeleton-topbar-loader .col-b:first-child .skeleton:first-of-type {
  width: 45%;
}
.skeleton-topbar-loader .col-b:first-child .skeleton:last-of-type {
  width: 20%;
}

.skeleton-button-group {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-left: 15px;
  padding-right: 15px;
}
.skeleton-button-group .skeleton-box {
  width: 120px;
  height: 42px;
  margin-left: 13px;
}

.breadcrumbs .text-loader .skeleton-box {
  width: 150px;
}

.kpi-loader {
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0 30px;
  z-index: 5;
}
.kpi-loader .skeleton-box {
  height: 75px;
}
.kpi-loader .kpi-skeleton {
  width: 200px;
  margin-left: 10px;
}

.skeleton-table-main {
  background-color: #f4f6f6;
  width: 140%;
  height: 100%;
}
.skeleton-table-main .skeleton-box {
  height: 100%;
}

.skeleton-main-info {
  padding-top: 10px;
}
.skeleton-main-info .col-md-6,
.skeleton-main-info .col-md-12 {
  margin-bottom: 20px;
}
.skeleton-main-info .skeleton-box {
  height: 20px;
}
.skeleton-main-info .skeleton-box.label {
  margin-bottom: 5px;
}
.skeleton-main-info .skeleton-box.short {
  width: 40%;
}
.skeleton-main-info .skeleton-box.medium {
  width: 65%;
}
.skeleton-main-info .skeleton-box.long {
  width: 80%;
}

.skeleton-side-info {
  padding-top: 20px;
}
.skeleton-side-info .skel-grp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
}
.skeleton-side-info .skel-grp .skel-col:first-of-type .skeleton-box {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.skeleton-side-info .skel-grp .skel-col:last-of-type {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 15px;
}
.skeleton-side-info .skel-grp .skel-col:last-of-type .skeleton-box {
  height: 20px;
}
.skeleton-side-info
  .skel-grp
  .skel-col:last-of-type
  .skeleton-box:first-of-type {
  width: 90%;
  margin-bottom: 5px;
}
.skeleton-side-info
  .skel-grp
  .skel-col:last-of-type
  .skeleton-box:last-of-type {
  width: 50%;
}

.skeleton-side-info-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;
}
.skeleton-side-info-btns .skeleton-box {
  height: 51px;
  width: 25%;
  margin-left: 5px;
  margin-right: 5px;
}

.skeleton-auto-assign-drawer {
  display: none !important;
}
.skeleton-auto-assign-drawer .skeleton-groups {
  margin-bottom: 30px;
}
.skeleton-auto-assign-drawer .skeleton-groups .skeleton-top {
  margin-bottom: 20px;
}
.skeleton-auto-assign-drawer .skeleton-groups .skeleton-top .skeleton {
  height: 20px;
  width: 40%;
}
.skeleton-auto-assign-drawer
  .skeleton-groups
  .skeleton-bottom
  .skeleton-bottom-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.skeleton-auto-assign-drawer
  .skeleton-groups
  .skeleton-bottom
  .skeleton-bottom-group
  .skeleton {
  height: 35px;
}
.skeleton-auto-assign-drawer
  .skeleton-groups
  .skeleton-bottom
  .skeleton-bottom-group
  .skeleton:nth-child(1) {
  width: calc(30% - 10px);
}
.skeleton-auto-assign-drawer
  .skeleton-groups
  .skeleton-bottom
  .skeleton-bottom-group
  .skeleton:nth-child(2) {
  width: 40px;
  margin-left: 10px;
  margin-right: 10px;
}
.skeleton-auto-assign-drawer
  .skeleton-groups
  .skeleton-bottom
  .skeleton-bottom-group
  .skeleton:nth-child(3) {
  width: calc(70% - 10px);
}

.skeleton-parameter-drawer {
  display: none !important;
}
.skeleton-parameter-drawer .skeleton-groups .skeleton-box {
  height: 35px;
  margin-bottom: 10px;
}
.skeleton-parameter-drawer .skeleton-groups .skeleton-box:first-of-type {
  border-radius: 50px;
  height: 40px;
  margin-bottom: 20px;
}

.skeleton-utm-form {
  display: none !important;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  overflow: auto;
  height: calc(100vh - 135px);
}
.skeleton-utm-form .utm-item {
  padding: 20px 20px 30px 20px;
  border-bottom: 1px solid #eaeaea;
}
.skeleton-utm-form .utm-item:last-of-type {
  border-bottom: none;
}
.skeleton-utm-form .utm-item .utm-item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.skeleton-utm-form .utm-item .utm-item-row .utm-item-col:first-of-type {
  width: 50%;
}
.skeleton-utm-form .utm-item .utm-item-row .utm-item-col:last-of-type {
  width: 50%;
}
.skeleton-utm-form .utm-item-title,
.skeleton-utm-form .utm-item-label {
  height: 20px;
}
.skeleton-utm-form .utm-item-input {
  height: 39px;
}
.skeleton-utm-form .utm-item-title {
  width: 20%;
  margin-bottom: 10px;
}
.skeleton-utm-form .utm-item-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}
.skeleton-utm-form .utm-item-group .utm-item-group-col {
  padding-left: 5px;
  padding-right: 5px;
}
.skeleton-utm-form .utm-item-group .utm-item-group-col:first-of-type {
  width: 20%;
  text-align: right;
}
.skeleton-utm-form .utm-item-group .utm-item-group-col:last-of-type {
  width: 80%;
}
.skeleton-utm-form .utm-item-group:last-of-type {
  margin-bottom: 0;
}
.skeleton-utm-form .item-presets {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}
.skeleton-utm-form .item-presets .item-preset-label {
  height: 20px;
  width: 30%;
  margin-bottom: 5px;
}
.skeleton-utm-form .item-presets .item-preset-input {
  height: 39px;
}

.custom-basic-editor {
  background-color: #fff;
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.custom-basic-editor .thread-editor-controls {
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding: 10px 15px;
}
.custom-basic-editor .thread-editor-controls button {
  background-color: transparent;
  color: #212121;
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 5px 7px;
  margin-right: 3px;
  font-size: 0.625rem;
}
.custom-basic-editor .thread-editor-controls button:hover,
.custom-basic-editor .thread-editor-controls button.is-active {
  background-color: #eee;
}
.custom-basic-editor .thread-editor-controls button[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
.custom-basic-editor .thread-editor-controls button i {
  margin-right: 0;
}
.custom-basic-editor .thread-editor-controls .editor-deck-btn {
  display: none;
}
.custom-basic-editor .thread-editor-controls .editor-deck-btn svg {
  margin-right: 6px;
}
.custom-basic-editor .thread-editor-controls .email-template-btn {
  display: none;
  height: 30px;
  vertical-align: middle;
}
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .icon-envelope-o {
  font-size: 14px;
  top: 2px;
  position: relative;
  margin-right: 6px;
}
.custom-basic-editor .thread-editor-controls .editor-deck-btn,
.custom-basic-editor .thread-editor-controls .email-template-btn {
  position: relative;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  position: absolute;
  top: 35px;
  right: 0;
  min-width: 250px;
  z-index: 2;
  display: none;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck.active,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck.active {
  display: block;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-title,
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-btn-group,
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list,
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .category-list,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-title,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-btn-group,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .category-list {
  padding: 12px 15px;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-title,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-title
  p,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-title
  p {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-title
  i,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-title
  i {
  font-size: 1rem;
  cursor: pointer;
  margin-right: -3px;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list {
  overflow: auto;
  max-height: 180px;
  border-top: 1px solid #f5f5f5;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .checkbox-group,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .checkbox-group {
  margin-bottom: 8px;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .checkbox-group
  label,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .checkbox-group
  label {
  position: relative;
  top: 0.5px;
  text-align: left;
  margin-bottom: 0;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .checkbox-group:last-of-type,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .checkbox-group:last-of-type {
  margin-bottom: 0;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .empty-box,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .empty-box {
  position: relative;
  top: unset;
  left: unset;
  -webkit-transform: unset;
  -ms-transform: unset;
  transform: unset;
  width: 100%;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .empty-box
  p,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .empty-box
  p {
  margin-bottom: 0;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group {
  margin-bottom: 12px;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  display: block;
  text-align: left;
  margin-bottom: 5px;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name-list,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name-list {
  padding-left: 8px;
  margin-bottom: 5px;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name-list
  span,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name-list
  span {
  display: block;
  text-align: left;
  text-transform: capitalize;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  cursor: pointer;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name-list
  span:hover,
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name-list
  span.selected,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name-list
  span:hover,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name-list
  span.selected {
  color: #0a87cf;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name-list:last-child,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-list
  .filter-group
  .filter-name-list:last-child {
  margin-bottom: 0;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-btn-group,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 12px;
  border-top: 1px solid #f5f5f5;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-btn-group
  span,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-btn-group
  span {
  cursor: pointer;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-left: 20px;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-btn-group
  span.btn-reset,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-btn-group
  span.btn-reset {
  color: #212121;
}
.custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn
  .filter-dropdown-deck
  .filter-btn-group
  span.btn-apply,
.custom-basic-editor
  .thread-editor-controls
  .email-template-btn
  .filter-dropdown-deck
  .filter-btn-group
  span.btn-apply {
  color: #0a87cf;
}
.custom-basic-editor .thread-editor-controls .resize-btn {
  display: none;
}
.custom-basic-editor .thread-editor-field .ProseMirror {
  padding: 20px 15px;
}
.custom-basic-editor .thread-editor-field .ProseMirror p,
.custom-basic-editor .thread-editor-field .ProseMirror ul,
.custom-basic-editor .thread-editor-field .ProseMirror ol {
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
}
.custom-basic-editor .thread-editor-field .ProseMirror ul,
.custom-basic-editor .thread-editor-field .ProseMirror ol {
  padding-left: 20px;
}
.custom-basic-editor .thread-editor-field .ProseMirror p,
.custom-basic-editor .thread-editor-field .ProseMirror li {
  line-height: 1.6;
}
.custom-basic-editor .thread-editor-field .ProseMirror ul {
  list-style-type: disc;
}
.custom-basic-editor .thread-editor-field .ProseMirror ol {
  list-style-type: decimal;
}
.custom-basic-editor .thread-editor-field .ProseMirror p {
  text-transform: none;
}
.custom-basic-editor .thread-editor-field .ProseMirror h4 {
  font-family: "avenirnextdemibold";
  font-size: 1rem;
  line-height: 2;
}
.custom-basic-editor .thread-editor-field .ProseMirror p + p {
  margin-top: 0;
}
.custom-basic-editor .thread-editor-field .ProseMirror em,
.custom-basic-editor .thread-editor-field .ProseMirror s {
  font-weight: inherit;
}
.custom-basic-editor .thread-editor-field .ProseMirror .mention {
  font-family: "avenirnextdemibold";
}
.custom-basic-editor .thread-editor-field .ProseMirror:focus {
  outline: none;
}
.custom-basic-editor .editor-btns-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 15px;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
}
.custom-basic-editor .editor-btns-group span {
  cursor: pointer;
}
.custom-basic-editor .editor-btns-group .btn-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.custom-basic-editor .editor-btns-group .btn-left span {
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.custom-basic-editor .editor-btns-group .btn-left span i {
  margin-right: 3px;
}
.custom-basic-editor .editor-btns-group .btn-right button {
  margin-left: 15px;
}
.custom-basic-editor .mention-items {
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
}
.custom-basic-editor .mention-items .item {
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  padding: 6px;
  font-size: 0.75rem;
  cursor: pointer;
}
.custom-basic-editor .mention-items .item:hover,
.custom-basic-editor .mention-items .item.is-selected {
  background-color: #eee !important;
}
.custom-basic-editor .mention-items .item span.user-avatar {
  background-color: #212121;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-family: "avenirnextdemibold";
  font-size: 0.625rem;
  margin-right: 8px;
}

.reply-email-control
  .custom-basic-editor
  .editor-deck-btn
  .filter-dropdown-deck,
.reply-email-control
  .custom-basic-editor
  .email-template-btn
  .filter-dropdown-deck {
  top: unset;
  bottom: 35px;
}
.reply-email-control.active
  .custom-basic-editor
  .editor-deck-btn
  .filter-dropdown-deck,
.reply-email-control.active
  .custom-basic-editor
  .email-template-btn
  .filter-dropdown-deck {
  top: 35px;
  bottom: unset;
}

.editor-display-text p,
.editor-display-text ul,
.editor-display-text ol {
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
}
.editor-display-text ul,
.editor-display-text ol {
  padding-left: 20px;
}
.editor-display-text p,
.editor-display-text li {
  line-height: 1.6;
}
.editor-display-text ul {
  list-style-type: disc;
}
.editor-display-text ol {
  list-style-type: decimal;
}
.editor-display-text p {
  text-transform: none;
}
.editor-display-text h4 {
  font-family: "avenirnextdemibold";
  font-size: 1rem;
  line-height: 2;
}
.editor-display-text em,
.editor-display-text s {
  font-weight: inherit;
}

#careers-content
  .custom-basic-editor.email-editor
  .thread-editor-controls
  .email-template-btn,
#careers-content
  .custom-basic-editor.email-editor
  .thread-editor-controls
  .editor-deck-btn {
  display: none;
}

.main-info-section {
  padding: 0 30px 15px 30px;
  height: calc(100vh - 153px);
}
.main-info-section .row.row-100 {
  height: 100%;
}
.main-info-section .row.row-100 .col-md-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}
.main-info-section .row.row-100 .col-md-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}
.main-info-section .top-info-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.main-info-section .top-info-box .col-box {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 15px;
  padding-right: 15px;
}
.main-info-section .top-info-box .col-box .avatar-text-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.main-info-section .top-info-box .col-box .avatar-text-box .text {
  width: 100%;
}
.main-info-section .top-info-box .col-box .avatar-text-box .text label,
.main-info-section .top-info-box .col-box .avatar-text-box .text span {
  display: block;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.main-info-section .top-info-box .col-box .avatar-text-box .text label {
  text-transform: uppercase;
  color: rgba(33, 33, 33, 0.5);
  margin-bottom: 3px;
}
.main-info-section .top-info-box .col-box .avatar-text-box .ls-text .ls-c {
  position: relative;
}
.main-info-section .top-info-box .col-box .avatar-text-box .ls-text .ls-c i {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #23a7f4;
  color: #fff;
  font-style: normal;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  font-size: 9px;
  margin-left: 5px;
  top: -1px;
}
.main-info-section
  .top-info-box
  .col-box
  .avatar-text-box
  .ls-text
  .ls-c
  .ls-info {
  background-color: #fff;
  color: #444;
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 10;
  min-width: 200px;
  display: block;
  opacity: 0;
  visibility: hidden;
  padding: 10px;
}
.main-info-section
  .top-info-box
  .col-box
  .avatar-text-box
  .ls-text
  .ls-c:hover
  .ls-info {
  opacity: 1;
  visibility: visible;
}
.main-info-section
  .top-info-box
  .col-box
  .avatar-text-box
  .ls-text
  .ls-color-1 {
  color: rgb(41, 174, 96);
}
.main-info-section
  .top-info-box
  .col-box
  .avatar-text-box
  .ls-text
  .ls-color-2 {
  color: rgb(255, 149, 97);
}
.main-info-section
  .top-info-box
  .col-box
  .avatar-text-box
  .ls-text
  .ls-color-3 {
  color: rgb(255, 58, 33);
}
.main-info-section .top-info-box .col-box .filter-box {
  position: relative;
}
.main-info-section .top-info-box .col-box .filter-box .filter-btn span {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.main-info-section .top-info-box .col-box .filter-box .filter-btn i {
  margin-right: 3px;
  margin-left: -3px;
  font-size: 1.125rem;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-btn.priority-btn
  span,
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-btn.status-btn
  span,
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-btn.category-btn
  span,
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-btn.admin-btn
  span {
  border-radius: 5px;
  padding: 5px 8px;
}
.main-info-section .top-info-box .col-box .filter-box .filter-dropdown {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  position: absolute;
  left: 0;
  min-width: 220px;
  z-index: 2;
  display: none;
}
.main-info-section .top-info-box .col-box .filter-box .filter-dropdown.active {
  display: block;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-title,
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-btn-group,
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list,
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .category-list {
  padding: 10px 15px;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-title
  p {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-title
  i {
  font-size: 1rem;
  cursor: pointer;
  margin-right: -3px;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list {
  overflow: auto;
  max-height: 300px;
  border-top: 1px solid #f5f5f5;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list
  span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  margin-bottom: 12px;
  cursor: pointer;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list
  span:last-of-type {
  margin-bottom: 0;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.priority-list
  i {
  font-size: 1.125rem;
  margin-right: 4px;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.priority-list
  span.font-color-high {
  color: #dd3444;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.priority-list
  span.font-color-medium {
  color: #ff9460;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.priority-list
  span.font-color-low {
  color: #1675e0;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group {
  margin-bottom: 10px;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name {
  font-family: "avenirnextdemibold";
  margin-bottom: 5px;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name.filter-color-1 {
  color: #00b3fe;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name.filter-color-2 {
  color: #1675e0;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name.filter-color-3 {
  color: #5064ff;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name.filter-color-4 {
  color: #48a39e;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name.filter-color-5 {
  color: #8896f7;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name.filter-color-6 {
  color: #9d14c6;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name.filter-color-7 {
  color: #189688;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name.filter-color-8 {
  color: #60c608;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name.filter-color-9 {
  color: #cb980d;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group
  .filter-name.filter-color-10 {
  color: #db0000;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-group:last-of-type {
  margin-bottom: 0;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-name-list {
  padding-left: 10px;
  margin-bottom: 5px;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-list.status-list
  .filter-name-list:last-of-type {
  margin-bottom: 0;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-top: 0;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-btn-group
  span {
  cursor: pointer;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-left: 20px;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-btn-group
  span.btn-reset {
  color: #212121;
}
.main-info-section
  .top-info-box
  .col-box
  .filter-box
  .filter-dropdown
  .filter-btn-group
  span.btn-apply {
  color: #0a87cf;
}
.main-info-section .information-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  height: calc(100% - 129px);
}
.main-info-section .information-box .box-white-custom {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.main-info-section .information-box .box-white-custom .title-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.main-info-section .information-box .box-white-custom .title-box i {
  margin-right: 5px;
  font-size: 1.25rem;
}
.main-info-section .information-box .box-white-custom .title-box span {
  text-transform: uppercase;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.main-info-section .information-box .box-white-custom .custom-scroll-box {
  height: calc(100vh - 350px);
}
.main-info-section
  .information-box
  .box-white-custom
  .custom-scroll-box
  .row.info-group {
  height: 100%;
  overflow-y: auto;
}
.main-info-section .information-box .basic-info-box {
  width: 100%;
}
.main-info-section .information-box .basic-info-box .info-group .col-md-12 {
  margin-bottom: 20px;
}
.main-info-section .information-box .basic-info-box .info-group .col-md-6 {
  margin-bottom: 25px;
}
.main-info-section .information-box .basic-info-box .info-group .col-md-6 label,
.main-info-section .information-box .basic-info-box .info-group .col-md-6 span,
.main-info-section .information-box .basic-info-box .info-group .col-md-6 a,
.main-info-section
  .information-box
  .basic-info-box
  .info-group
  .col-md-12
  label,
.main-info-section .information-box .basic-info-box .info-group .col-md-12 span,
.main-info-section .information-box .basic-info-box .info-group .col-md-12 a {
  display: block;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.main-info-section .information-box .basic-info-box .info-group .col-md-6 label,
.main-info-section
  .information-box
  .basic-info-box
  .info-group
  .col-md-12
  label {
  text-transform: uppercase;
  color: rgba(33, 33, 33, 0.5);
  margin-bottom: 3px;
}
.main-info-section .information-box .basic-info-box .info-group .col-md-6 span,
.main-info-section
  .information-box
  .basic-info-box
  .info-group
  .col-md-12
  span {
  white-space: break-spaces;
  word-break: break-word;
}
.main-info-section
  .information-box
  .basic-info-box
  .info-group
  .col-md-6
  span.link-span,
.main-info-section
  .information-box
  .basic-info-box
  .info-group
  .col-md-12
  span.link-span {
  color: #0a87cf;
}
.main-info-section
  .information-box
  .basic-info-box
  .info-group
  .col-md-6
  .copy-btn,
.main-info-section
  .information-box
  .basic-info-box
  .info-group
  .col-md-12
  .copy-btn {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.main-info-section
  .information-box
  .basic-info-box
  .info-group
  .col-md-6:hover
  .copy-btn,
.main-info-section
  .information-box
  .basic-info-box
  .info-group
  .col-md-12:hover
  .copy-btn {
  opacity: 1;
  visibility: visible;
}
.main-info-section .information-box.w-agent-box .basic-info-box {
  width: calc(65% - 15px);
  margin-right: 15px;
}
.main-info-section .information-box.w-agent-box .agent-info-box {
  width: 35%;
}
.main-info-section .information-box.w-agent-box .agent-info-box select {
  background-color: transparent;
  border: 1px solid rgba(112, 112, 112, 0.2);
  color: #212121;
  border-radius: 10px;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  padding: 10px 15px;
  width: 100%;
  line-height: unset;
  height: unset;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .css-iuj545-field {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .css-142cf7a-root {
  right: 15px;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper {
  position: relative;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .lds-ellipsis {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .agent-changed
  .input-wrap,
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .agent-static
  .input-wrap {
  margin-bottom: 15px;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .agent-changed
  .input-wrap:last-of-type,
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .agent-static
  .input-wrap:last-of-type {
  margin-bottom: 0;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .agent-changed
  label,
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .agent-changed
  span,
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .agent-static
  label,
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .agent-static
  span {
  display: block;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .agent-changed
  label,
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .agent-static
  label {
  text-transform: uppercase;
  color: rgba(33, 33, 33, 0.5);
  margin-bottom: 3px;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .btns-to-change {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 20px;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .btns-to-change
  span {
  cursor: pointer;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-left: 20px;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .btns-to-change
  span.cancel {
  color: #212121;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper
  .btns-to-change
  span.assign {
  color: #0a87cf;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper.show-me
  .agent-changed {
  display: block;
  margin-bottom: 15px;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper.show-me
  .agent-static {
  display: none;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper.show-me
  .btns-to-change {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.main-info-section
  .information-box.w-agent-box
  .agent-info-box
  .agent-information-wrapper.hide-detail
  .agent-changed {
  display: none;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.admin-btn
  span {
  background: rgba(10, 135, 207, 0.06);
  color: #0a87cf;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.priority-btn
  span.font-color-high {
  background: rgba(221, 52, 68, 0.06);
  color: #dd3444;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.priority-btn
  span.font-color-medium {
  background: rgba(255, 148, 96, 0.06);
  color: #ff9460;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.priority-btn
  span.font-color-low {
  background: rgba(22, 117, 224, 0.06);
  color: #1675e0;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-1 {
  background: rgba(0, 179, 254, 0.06);
  color: #00b3fe;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-2 {
  background: rgba(22, 117, 224, 0.06);
  color: #1675e0;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-3 {
  background: rgba(80, 100, 255, 0.06);
  color: #5064ff;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-4 {
  background: rgba(72, 163, 158, 0.06);
  color: #48a39e;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-5 {
  background: rgba(136, 150, 247, 0.06);
  color: #8896f7;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-6 {
  background: rgba(157, 20, 198, 0.06);
  color: #9d14c6;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-7 {
  background: rgba(24, 150, 136, 0.06);
  color: #189688;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-8 {
  background: rgba(96, 198, 8, 0.06);
  color: #60c608;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-9 {
  background: rgba(203, 152, 13, 0.06);
  color: #cb980d;
}
.main-info-section.leads-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-10 {
  background: rgba(219, 0, 0, 0.06);
  color: #db0000;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.admin-btn
  span {
  background: rgba(10, 135, 207, 0.06);
  color: #0a87cf;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-1 {
  background: rgba(40, 174, 96, 0.06);
  color: #28ae60;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-2 {
  background: rgba(255, 148, 96, 0.06);
  color: #ff9460;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-3 {
  background: rgba(221, 52, 68, 0.06);
  color: #dd3444;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-4 {
  background: rgba(22, 117, 224, 0.06);
  color: #1675e0;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-5 {
  background: rgba(4, 155, 167, 0.06);
  color: #049ba7;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.status-btn
  span.status-color-6 {
  background: rgba(203, 152, 13, 0.06);
  color: #cb980d;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.category-btn
  .category-text {
  position: relative;
  background-color: rgba(10, 135, 207, 0.1);
  color: #0a87cf;
  display: block;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.category-btn
  .hover-category-text {
  background-color: #fff;
  color: #212121;
  -webkit-box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  position: absolute;
  top: 20px;
  left: -1px;
  padding: 5px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.category-btn
  .hover-category-text
  span {
  display: block;
  font-family: "avenirnextmedium";
  font-size: 0.625rem;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-btn.category-btn:hover
  .hover-category-text {
  visibility: visible;
  opacity: 1;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  span {
  font-family: "avenirnextdemibold";
  margin-bottom: 10px;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  span.filter-color1 {
  color: #28ae60;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  span.filter-color2 {
  color: #ff9460;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  span.filter-color3 {
  color: #dd3444;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  span.filter-color4 {
  color: #1675e0;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  span.filter-color5 {
  color: #049ba7;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  span.filter-color6 {
  color: #cb980d;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .checkbox-group {
  margin-bottom: 8px;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .checkbox-group
  label {
  position: relative;
  top: 0.5px;
  margin-bottom: 0;
}
.main-info-section.influencers-info
  .top-info-box
  .filter-box
  .filter-dropdown
  .filter-list
  .filter-group
  .checkbox-group:last-of-type {
  margin-bottom: 0;
}
.main-info-section.influencers-info
  .information-box.information-influencer-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.main-info-section.influencers-info
  .information-box.information-influencer-box
  .basic-info-box {
  width: calc(50% - 15px);
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.activity-side-box button.plain-btn {
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.activity-side-box button.plain-btn i {
  font-size: 1.125rem;
  margin-right: 5px;
}
.activity-side-box button.plain-btn.cancel-btn {
  color: #212121;
}
.activity-side-box button.plain-btn.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.activity-side-box .activity-control-box {
  position: relative;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  height: 51px;
}
.activity-side-box .activity-control-box .activity-btns {
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 15px 20px;
}
.activity-side-box .activity-control-box .activity-btns::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
  background-color: rgba(33, 33, 33, 0.2);
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.activity-side-box .activity-control-box .activity-btns p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.activity-side-box .activity-control-box .activity-btns p span {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
.activity-side-box .activity-control-box .activity-btns p .notif-count {
  position: relative;
  background-color: #e60000;
  color: #fff;
  border-radius: 50%;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-left: 5px;
  font-size: 0.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.activity-side-box .activity-control-box .activity-btns p .notif-count i {
  display: none;
  position: absolute;
  top: -12px;
  right: -12px;
  font-style: normal;
  font-size: 8px;
  z-index: 2;
  background-color: #e60000;
  color: #fff;
  padding: 1px 3px;
}
.activity-side-box .activity-control-box .activity-btns p .notif-count.empty {
  background-color: #0a87cf;
}
.activity-side-box .activity-control-box .activity-btns p .notif-count.empty i {
  display: none;
}
.activity-side-box .activity-control-box .activity-btns.active {
  background: rgba(10, 135, 207, 0.06);
}
.activity-side-box .activity-control-box .activity-btns.active p {
  color: #0a87cf;
}
.activity-side-box .activity-control-box .activity-btns.active::after {
  background-color: #0a87cf;
}
.activity-side-box .activity-control-box .swiper-custom-btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff !important;
  cursor: pointer;
  height: 51px;
  width: 30px;
  padding: 0;
  z-index: 1;
}
.activity-side-box .activity-control-box .swiper-custom-btn i {
  color: #212121;
  font-size: 1.25rem;
  display: block;
}
.activity-side-box .activity-control-box .swiper-custom-btn.prevSlide {
  left: 0;
  border-top-left-radius: 10px;
  -webkit-box-shadow: 1.5px 0 2px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 1.5px 0 2px 0 rgba(0, 0, 0, 0.16);
}
.activity-side-box .activity-control-box .swiper-custom-btn.nextSlide {
  right: 0;
  border-top-right-radius: 10px;
  -webkit-box-shadow: -1.5px 0 2px 0 rgba(0, 0, 0, 0.16);
  box-shadow: -1.5px 0 2px 0 rgba(0, 0, 0, 0.16);
}
.activity-side-box
  .activity-control-box
  .swiper-custom-btn.swiper-button-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.activity-side-box .custom-scroll-box {
  position: relative;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 20px;
  height: calc(100vh - 220px);
  overflow: auto;
}
.activity-side-box .custom-scroll-box .loading-box {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}
.activity-side-box .tab-content-box {
  padding: 20px 0;
}
.activity-side-box .tab-content-box .empty-tab-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.activity-side-box .tab-content-box .empty-tab-content .empty-content {
  text-align: center;
}
.activity-side-box .tab-content-box .empty-tab-content .empty-content h6 {
  font-family: "avenirnextdemibold";
  font-size: 1.25rem;
  margin-bottom: 10px;
}
.activity-side-box .tab-content-box .empty-tab-content .empty-content p {
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  margin-bottom: 15px;
}
.activity-side-box .tab-content-box .empty-tab-content .empty-content span {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.activity-side-box .tab-content-box .empty-tab-content .empty-content span i {
  font-size: 1.125rem;
  margin-top: -2px;
  margin-right: 5px;
}
.activity-side-box .tab-content-box .inner-title-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}
.activity-side-box .tab-content-box .inner-title-box p {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.activity-side-box .tab-content-box .source-group,
.activity-side-box .tab-content-box .session-group,
.activity-side-box .tab-content-box .interest-group,
.activity-side-box .tab-content-box .note-group {
  position: relative;
  z-index: 1;
}
.activity-side-box .tab-content-box .source-group,
.activity-side-box .tab-content-box .session-group,
.activity-side-box .tab-content-box .interest-group {
  margin-bottom: 20px;
}
.activity-side-box .tab-content-box .source-group:last-of-type,
.activity-side-box .tab-content-box .session-group:last-of-type,
.activity-side-box .tab-content-box .interest-group:last-of-type {
  margin-bottom: 0;
}
.activity-side-box .tab-content-box .tab-content-list-box .source div,
.activity-side-box .tab-content-box .tab-content-list-box .session div {
  margin-bottom: 15px;
}
.activity-side-box
  .tab-content-box
  .tab-content-list-box
  .source
  div:last-of-type,
.activity-side-box
  .tab-content-box
  .tab-content-list-box
  .session
  div:last-of-type {
  margin-bottom: 0;
}
.activity-side-box .tab-content-box .tab-content-list-box p,
.activity-side-box .tab-content-box .tab-content-list-box span {
  display: block;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.activity-side-box .tab-content-box .tab-content-list-box p {
  text-transform: uppercase;
  color: rgba(33, 33, 33, 0.5);
  margin-bottom: 3px;
}
.activity-side-box .recent-activity-box {
  padding: 20px 0;
}
.activity-side-box
  .recent-activity-box
  ul
  li:last-of-type
  .activity-box
  .activity-icon-group
  .line-box {
  display: none;
}
.activity-side-box .recent-activity-box .activity-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.activity-side-box .recent-activity-box .activity-box .activity-icon-group {
  margin-top: 5px;
  margin-right: 15px;
}
.activity-side-box
  .recent-activity-box
  .activity-box
  .activity-icon-group
  .icon-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.activity-side-box
  .recent-activity-box
  .activity-box
  .activity-icon-group
  .icon-box
  i {
  color: rgba(33, 33, 33, 0.2);
  font-size: 0.875rem;
}
.activity-side-box
  .recent-activity-box
  .activity-box
  .activity-icon-group
  .line-box {
  background-color: rgba(33, 33, 33, 0.2);
  width: 1px;
  height: calc(100% - 25px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5px;
}
.activity-side-box .recent-activity-box .activity-box .activity-detail-group {
  margin-bottom: 20px;
}
.activity-side-box
  .recent-activity-box
  .activity-box
  .activity-detail-group
  .main-details
  span,
.activity-side-box
  .recent-activity-box
  .activity-box
  .activity-detail-group
  .main-details
  a {
  font-family: "avenirnextmedium";
  font-size: 0.875rem;
}
.activity-side-box
  .recent-activity-box
  .activity-box
  .activity-detail-group
  .sub-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.activity-side-box
  .recent-activity-box
  .activity-box
  .activity-detail-group
  .sub-details
  span {
  color: rgba(33, 33, 33, 0.5);
  font-size: 0.75rem;
}
.activity-side-box
  .recent-activity-box
  .activity-box
  .activity-detail-group
  .sub-details
  i {
  position: relative;
  top: -2px;
  font-size: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.activity-side-box .recent-activity-box .activity-box .activity-detail-group a,
.activity-side-box
  .recent-activity-box
  .activity-box
  .activity-detail-group
  .highlight-text {
  color: #0a87cf;
}
.activity-side-box .recent-activity-box .activity-box .activity-detail-group a {
  text-decoration: underline;
}
.activity-side-box .source-box .source-list-box {
  background-color: rgba(0, 179, 254, 0.05);
  border-radius: 10px;
  padding: 15px;
}
.activity-side-box .source-box .source-list-box .q-mark-icon {
  position: relative;
  top: -1px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #0a87cf;
  color: #fff;
  margin-left: 5px;
  font-size: 0.625rem;
  font-style: normal;
  cursor: default;
}
.activity-side-box
  .source-box
  .source-list-box
  .q-mark-icon
  .info-not-available {
  background-color: #fff;
  color: #212121;
  -webkit-box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  display: block;
  min-width: 300px;
  position: absolute;
  top: 50%;
  left: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 6px 12px;
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}
.activity-side-box
  .source-box
  .source-list-box
  .q-mark-icon:hover
  .info-not-available {
  opacity: 1;
  visibility: visible;
}
.activity-side-box .session-box .session-list-box p {
  font-family: "avenirnextdemibold";
}
.activity-side-box .session-box .session-list-box ul.accordion {
  margin-top: 10px;
}
.activity-side-box .session-box .session-list-box ul.accordion li {
  margin-bottom: 10px;
  border-left: 3px solid #0a87cf;
  padding: 15px 0 15px 15px;
}
.activity-side-box .session-box .session-list-box ul.accordion .col {
  padding: 0;
}
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_button {
  position: relative;
  background-color: transparent;
  text-align: left;
  width: 100%;
  padding: 0;
}
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_button
  div {
  padding-right: 20px;
}
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_button
  div
  p {
  font-size: 0.875rem;
}
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_button
  div
  span {
  display: block;
  font-size: 0.75rem;
  margin-top: 5px;
}
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_button
  div
  span
  time {
  color: rgba(33, 33, 33, 0.5);
}
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_button
  span.control {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  font-size: 1.125rem;
  background-color: #0a87cf;
  color: #fff;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_content_wrapper {
  overflow: hidden;
}
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_content_wrapper
  div {
  margin-top: 15px;
}
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_content_wrapper
  div
  p,
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_content_wrapper
  div
  span {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.activity-side-box
  .session-box
  .session-list-box
  ul.accordion
  .accordion_content_wrapper
  div
  p {
  color: rgba(33, 33, 33, 0.5);
}
.activity-side-box .interest-box .interest-btn-group {
  position: sticky;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: -10px -20px 0 -20px;
  padding: 10px 20px;
  z-index: 2;
  background-color: #fff;
}
.activity-side-box .interest-box .interest-btn-group p {
  color: #212121;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.activity-side-box .interest-box .interest-btn-group .int-btns {
  border: 1px solid #ddd;
  border-radius: 4px;
}
.activity-side-box .interest-box .interest-btn-group button {
  background-color: transparent;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #888;
  width: 35px;
  height: 25px;
}
.activity-side-box .interest-box .interest-btn-group button i {
  font-size: 14px;
}
.activity-side-box .interest-box .interest-btn-group button:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.activity-side-box .interest-box .interest-btn-group button.active {
  background-color: #23a7f4;
  color: #fff;
}
.activity-side-box .interest-box .word-cloud-group {
  border-top: 1px solid #ddd;
  margin-left: -20px;
  margin-right: -20px;
  padding: 15px 20px 0 20px;
}
.activity-side-box .interest-box .word-cloud-group .int-view {
  display: none;
}
.activity-side-box .interest-box .word-cloud-group .int-view.active {
  display: block;
}
.activity-side-box .interest-box .word-cloud-group .int-view svg {
  margin-left: -15px;
  margin-right: -15px;
}
.activity-side-box .interest-box .word-cloud-group .wc-title {
  display: block;
  color: #a3a3a3;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 15px;
}
.activity-side-box .interest-box .word-cloud-group .wc-tag {
  position: relative;
  font-weight: 500;
  margin: 0px 3px;
  vertical-align: middle;
  display: inline-block;
}
.activity-side-box .interest-box .word-cloud-group .wc-tag .wc-info {
  background-color: #fff;
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  position: absolute;
  left: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  padding: 10px;
  display: none;
  font-size: 12px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}
.activity-side-box .interest-box .word-cloud-group .wc-tag .wc-info p {
  font-weight: 600;
}
.activity-side-box .interest-box .word-cloud-group .wc-tag:hover .wc-info {
  display: block;
  opacity: 1;
  visibility: visible;
}
.activity-side-box .interest-box .word-cloud-group .int-list-view ol {
  padding-left: 15px;
  margin-bottom: 0;
}
.activity-side-box .interest-box .word-cloud-group .int-list-view ol li {
  font-size: 10px;
  margin-bottom: 15px;
}
.activity-side-box
  .interest-box
  .word-cloud-group
  .int-list-view
  ol
  li
  .int-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.activity-side-box
  .interest-box
  .word-cloud-group
  .int-list-view
  ol
  li
  .int-box
  div.bx {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}
.activity-side-box
  .interest-box
  .word-cloud-group
  .int-list-view
  ol
  li
  .int-box
  div.bx
  span {
  font-size: 0.75rem;
}
.activity-side-box
  .interest-box
  .word-cloud-group
  .int-list-view
  ol
  li
  .int-box
  div.bx
  .int-bar {
  position: relative;
  background-color: rgba(10, 135, 207, 0.1);
  border-radius: 2px;
  padding: 5px 10px;
}
.activity-side-box
  .interest-box
  .word-cloud-group
  .int-list-view
  ol
  li
  .int-box
  div.bx
  .int-bar
  .int-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #444;
  border-radius: 2px;
}
.activity-side-box
  .interest-box
  .word-cloud-group
  .int-list-view
  ol
  li
  .int-box
  div.bx
  .int-bar
  span {
  display: block;
  position: relative;
  color: #212121;
  z-index: 1;
  font-weight: 500;
  font-size: 0.625rem;
}
.activity-side-box
  .interest-box
  .word-cloud-group
  .int-list-view
  ol
  li
  .int-box
  div.bx
  .int-bar
  span.sm-t {
  color: #888;
}
.activity-side-box .interest-box .word-cloud-group .int-list-view button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.activity-side-box .notes-box .inner-title-box {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.activity-side-box .notes-box .inner-title-box .note-create-box {
  overflow: hidden;
  width: 100%;
}
.activity-side-box .notes-box .inner-title-box .note-create-box .note-wrapper {
  margin-top: 20px;
}
.activity-side-box .notes-box .notes-list .threads {
  background-color: rgba(10, 135, 207, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .thread-topic
  .thread-creator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .thread-topic
  .thread-creator
  .avatar-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .thread-topic
  .thread-creator
  .avatar-box
  p {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  padding-left: 10px;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .thread-topic
  .thread-creator
  .timestamp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .thread-topic
  .thread-creator
  .timestamp
  span {
  color: rgba(33, 33, 33, 0.5);
  font-size: 0.625rem;
}
.activity-side-box .notes-box .notes-list .threads .thread-reply-box {
  position: relative;
  padding-left: 16px;
}
.activity-side-box .notes-box .notes-list .threads .thread-reply {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 10px 8px 10px;
  margin-top: 15px;
}
.activity-side-box .notes-box .notes-list .threads .thread-reply .avatar-box {
  padding-right: 10px;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .thread-reply
  .avatar-box
  span {
  width: 25px;
  height: 25px;
  font-size: 0.625rem;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .thread-reply
  .edit-delete-box {
  top: 0;
}
.activity-side-box .notes-box .notes-list .threads .make-thread-reply {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: 15px;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .make-thread-reply
  .reply-action {
  text-align: right;
  width: 100%;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .make-thread-reply
  .note-create-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .make-thread-reply
  .note-create-box
  .avatar-box {
  margin-top: 10px;
  padding-right: 10px;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .make-thread-reply
  .note-create-box
  .avatar-box
  span {
  width: 27px;
  height: 27px;
  font-size: 0.625rem;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .make-thread-reply
  .note-create-box
  .note-wrapper {
  width: 100%;
}
.activity-side-box .notes-box .notes-list .threads .edit-delete-box {
  position: relative;
  padding-left: 10px;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .edit-delete-box
  .btn-ellipsis {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  color: rgba(33, 33, 33, 0.5);
  font-size: 0.75rem;
  opacity: 1;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .edit-delete-box
  .btn-ellipsis:focus-within
  > .btn-groups {
  display: block;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .edit-delete-box
  .btn-groups {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  border-radius: 5px;
  position: absolute;
  right: 0;
  display: none;
  z-index: 1;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .edit-delete-box
  .btn-groups
  button {
  background-color: transparent;
  color: #212121;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 0;
  width: 100%;
  padding: 8px 16px;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .edit-delete-box
  .btn-groups
  button
  i {
  font-size: 1.125rem;
  margin-right: 5px;
}
.activity-side-box
  .notes-box
  .notes-list
  .threads
  .edit-delete-box
  .btn-groups
  button:last-child {
  border-bottom: none;
}
.activity-side-box .email-box {
  padding-bottom: 0;
  height: 100%;
}
.activity-side-box .email-box .email-control-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}
.activity-side-box .email-box .email-control-box .email-control-btn {
  cursor: pointer;
  background-color: rgba(10, 135, 207, 0.1);
  color: #0a87cf;
  border-radius: 10px;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  padding: 6px 12px;
  margin-right: 12px;
}
.activity-side-box .email-box .email-control-box .email-control-btn.active {
  background-color: #0a87cf;
  color: #fff;
}
.activity-side-box .email-box .email-type-box {
  height: calc(100% - 55px);
}
.activity-side-box .email-box .email-type-box .et-box {
  display: none;
  height: 100%;
}
.activity-side-box .email-box .email-type-box .et-box.active {
  display: block;
}
.activity-side-box .email-box .email-list,
.activity-side-box .email-box .email-single {
  height: calc(100% - 55px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
}
.activity-side-box .email-box .email-list .email-content-box {
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
}
.activity-side-box .email-box .email-list .email-content-box:last-of-type {
  border-bottom: none;
}
.activity-side-box .email-box .email-list .email-content-box p {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  color: #212121;
}
.activity-side-box .email-box .email-list .email-content-box .email-name {
  width: 20%;
}
.activity-side-box .email-box .email-list .email-content-box .email-details {
  width: 60%;
  padding-left: 10px;
  padding-right: 10px;
}
.activity-side-box .email-box .email-list .email-content-box .email-details p {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0;
}
.activity-side-box
  .email-box
  .email-list
  .email-content-box
  .email-details
  span {
  color: rgba(33, 33, 33, 0.5);
  display: block;
  font-weight: 400;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.activity-side-box .email-box .email-list .email-content-box .email-date {
  text-align: right;
  width: 20%;
}
.activity-side-box .email-box .email-list .email-content-box .email-date p {
  font-size: 10px;
}
@media screen and (min-width: 1600px) {
  .activity-side-box .email-box .email-list .email-content-box .email-date {
    width: 30%;
  }
}
.activity-side-box .email-box .email-list .email-content-box.read {
  background-color: rgba(10, 135, 207, 0.02);
}
.activity-side-box .email-box .email-list .email-content-box.read p,
.activity-side-box .email-box .email-list .email-content-box.read span {
  color: rgba(33, 33, 33, 0.5);
}
.activity-side-box .email-box .compose-email-control,
.activity-side-box .email-box .reply-email-control {
  background-color: #fff;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 15px 0;
}
.activity-side-box .email-box .compose-email-control {
  text-align: right;
}
.activity-side-box .email-box .email-single .reply-email-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.activity-side-box .email-box .email-single .reply-email-box:last-of-type {
  padding-bottom: 0;
  border-bottom: none;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .col-box:last-of-type {
  width: 100%;
  padding-left: 15px;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-top
  p {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-top
  div:first-of-type
  p
  span {
  color: rgba(33, 33, 33, 0.5);
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-top
  div:first-of-type
  p:last-of-type {
  color: rgba(33, 33, 33, 0.5);
  margin-top: -3px;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-bottom
  h4 {
  font-family: "avenirnextdemibold";
  font-size: 1rem;
  line-height: 2;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-bottom
  p,
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-bottom
  ul,
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-bottom
  ol,
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-bottom
  div
  div {
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-bottom
  a {
  display: block;
  word-break: break-all;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-bottom
  p
  + p {
  margin-top: 0;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-bottom
  .toggle-content {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: #e8eaed;
  border-radius: 5px;
  padding: 0px 6px;
  cursor: pointer;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-bottom
  .toggle-content
  i {
  font-size: 12px;
}
.activity-side-box
  .email-box
  .email-single
  .reply-email-box
  .reply-details
  .reply-bottom
  div {
  word-break: break-word;
}
.activity-side-box .email-box .reply-email-control .toggle-group,
.activity-side-box .email-box .reply-email-control .button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.activity-side-box .email-box .reply-email-control .two-btn button {
  margin-left: 12px;
}
.activity-side-box .email-box .reply-email-control .two-btn button:first-child {
  margin-left: 0;
}
.activity-side-box .email-box .reply-email-control .compose-reply {
  margin-top: -15px;
}
.activity-side-box .email-box .reply-email-control .compose-reply .input-box {
  margin-bottom: 15px;
}
.activity-side-box
  .email-box
  .reply-email-control
  .compose-reply
  .input-box
  .email-editor {
  border-top: none;
}
.activity-side-box
  .email-box
  .reply-email-control
  .compose-reply
  .custom-basic-editor
  .thread-editor-controls {
  position: relative;
}
.activity-side-box
  .email-box
  .reply-email-control
  .compose-reply
  .custom-basic-editor
  .thread-editor-field {
  max-height: 130px;
  overflow: auto;
}
.activity-side-box
  .email-box
  .reply-email-control
  .compose-reply
  .custom-basic-editor
  .thread-editor-controls
  .editor-deck-btn,
.activity-side-box
  .email-box
  .reply-email-control
  .compose-reply
  .custom-basic-editor
  .thread-editor-controls
  .email-template-btn {
  display: inline-block;
}
.activity-side-box .email-box .reply-email-control .compose-reply .resize-btn {
  display: block;
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.activity-side-box .email-box .reply-email-control.active {
  height: 100%;
}
.activity-side-box
  .email-box
  .reply-email-control.active
  .compose-reply
  .input-box {
  height: calc(100vh - 300px);
}
.activity-side-box
  .email-box
  .reply-email-control.active
  .compose-reply
  .custom-basic-editor {
  height: calc(100vh - 300px);
}
.activity-side-box
  .email-box
  .reply-email-control.active
  .compose-reply
  .custom-basic-editor
  .thread-editor-field {
  max-height: unset;
  height: calc(100vh - 350px);
}
.activity-side-box .chat-box {
  padding-top: 0;
  padding-bottom: 0;
}
.activity-side-box .chat-box .search-bar {
  background-color: #fff;
  position: sticky;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 1;
}
.activity-side-box .chat-box .search-bar .input-search {
  position: relative;
  border: 1px solid rgba(112, 112, 112, 0.2);
  border-radius: 10px;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.activity-side-box .chat-box .search-bar .input-search i {
  position: absolute;
  left: 10px;
  font-size: 1.125rem;
}
.activity-side-box .chat-box .search-bar .input-search input {
  border: none;
  padding-left: 40px;
}
.activity-side-box .chat-box .search-bar .input-search.active {
  opacity: 1;
  visibility: visible;
}
.activity-side-box .chat-box .search-bar .search-toggle {
  cursor: pointer;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
  color: #212121;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin-left: 15px;
}
.activity-side-box .chat-box .search-bar .search-toggle i {
  font-size: 1.125rem;
}
.activity-side-box .chat-box .main-chat-box {
  padding-bottom: 10px;
}
.activity-side-box .chat-box .main-chat-box .chat-date-time,
.activity-side-box .chat-box .main-chat-box .chat-message,
.activity-side-box .chat-box .main-chat-box .chat-reply {
  margin-bottom: 15px;
}
.activity-side-box .chat-box .main-chat-box .chat-date-time {
  text-align: center;
}
.activity-side-box .chat-box .main-chat-box .chat-date-time span {
  color: rgba(33, 33, 33, 0.3);
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.activity-side-box .chat-box .main-chat-box .text-box {
  border-radius: 5px;
  padding: 12px;
}
.activity-side-box .chat-box .main-chat-box .text-box p {
  font-size: 0.75rem;
}
.activity-side-box .chat-box .main-chat-box .chat-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.activity-side-box .chat-box .main-chat-box .chat-message .user-message-icon {
  padding-bottom: 5px;
}
.activity-side-box .chat-box .main-chat-box .chat-message .user-message-text {
  position: relative;
  padding-left: 15px;
}
.activity-side-box
  .chat-box
  .main-chat-box
  .chat-message
  .user-message-text
  .name-box
  p {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextdemibold";
  font-size: 0.625rem;
  margin-bottom: 3px;
}
.activity-side-box
  .chat-box
  .main-chat-box
  .chat-message
  .user-message-text
  .text-box {
  background-color: #f1f1f1;
}
.activity-side-box
  .chat-box
  .main-chat-box
  .chat-message
  .user-message-text
  .text-box
  p {
  word-break: break-word;
}
.activity-side-box
  .chat-box
  .main-chat-box
  .chat-message
  .user-message-text
  .text-box::after {
  content: "";
  width: 0;
  height: 0;
  border-left: none;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 8px solid #f1f1f1;
  position: absolute;
  left: 7px;
  bottom: 15px;
}
.activity-side-box
  .chat-box
  .main-chat-box
  .chat-message
  .user-message-text
  .text-box
  a {
  color: #0a87cf;
  word-break: break-all;
}
.activity-side-box
  .chat-box
  .main-chat-box
  .chat-message
  .user-message-text
  .text-box
  a:hover {
  text-decoration: underline;
}
.activity-side-box .chat-box .main-chat-box .chat-reply {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  max-width: 75%;
  margin-left: auto;
}
.activity-side-box .chat-box .main-chat-box .chat-reply .reply-box {
  padding-right: 10px;
}
.activity-side-box .chat-box .main-chat-box .chat-reply .reply-box .name-box p {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextdemibold";
  font-size: 0.625rem;
  margin-bottom: 3px;
}
.activity-side-box .chat-box .main-chat-box .chat-reply .reply-box .text-box {
  background-color: #0a87cf;
}
.activity-side-box .chat-box .main-chat-box .chat-reply .reply-box .text-box p {
  color: #fff;
  word-break: break-word;
}
.activity-side-box
  .chat-box
  .main-chat-box
  .chat-reply
  .reply-box
  .text-box
  p
  a {
  word-break: break-all;
  color: #fff;
}
.activity-side-box
  .chat-box
  .main-chat-box
  .chat-reply
  .reply-box
  .text-box
  p
  a:hover {
  text-decoration: underline;
}
.activity-side-box .chat-box .main-chat-box .chat-reply .reply-status .status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  padding-bottom: 5px;
  color: #0a87cf;
}
.activity-side-box
  .chat-box
  .main-chat-box
  .chat-reply
  .reply-status
  .status
  i {
  font-size: 15px;
}
.activity-side-box
  .chat-box
  .main-chat-box
  .chat-reply
  .reply-status
  .status
  img {
  width: 17px;
  height: 17px;
}
.activity-side-box .chat-box .reply-input-bar {
  background-color: #fff;
  -webkit-box-shadow: 0px -1px 7px rgba(0, 0, 0, 0.08);
  box-shadow: 0px -1px 7px rgba(0, 0, 0, 0.08);
  position: sticky;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  padding: 15px;
}
.activity-side-box .chat-box .reply-input-bar input {
  margin-right: 10px;
}
.activity-side-box .platform-box .platform-list-box .platform {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}
.activity-side-box .platform-box .platform-list-box .platform-col {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-info {
  position: relative;
  display: block;
  padding: 20px;
  border-radius: 10px;
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-info:hover
  .platform-btns {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-info:hover
  .platform-details {
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-icon {
  text-align: center;
  margin-bottom: 15px;
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-icon
  img {
  max-width: 60px;
  height: 60px;
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-details
  div {
  text-align: center;
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-details
  div
  p,
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-details
  div
  span {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-details
  div
  p {
  color: #212121;
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-details
  div
  span {
  display: block;
  color: rgba(33, 33, 33, 0.5);
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-btns {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col
  .platform-btns
  button {
  min-width: 70px;
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col.facebook
  .platform-info {
  background: rgba(24, 119, 242, 0.06);
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col.youtube
  .platform-info {
  background: rgba(244, 66, 54, 0.06);
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col.twitter
  .platform-info {
  background: rgba(0, 179, 244, 0.06);
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col.instagram
  .platform-info {
  background: rgba(185, 50, 131, 0.06);
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col.tiktok
  .platform-info {
  background: rgba(33, 3, 24, 0.06);
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col.kumu
  .platform-info {
  background: rgba(10, 135, 207, 0.06);
}
.activity-side-box
  .platform-box
  .platform-list-box
  .platform-col.others
  .platform-info {
  background: rgba(72, 163, 158, 0.06);
}
.activity-side-box .clients-box .forms-tab .checkbox-group-list {
  background-color: rgba(10, 135, 207, 0.1);
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 10px;
  padding: 12px 15px;
}
.activity-side-box .clients-box .forms-tab .checkbox-group-list .check-col {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
}
.activity-side-box
  .clients-box
  .forms-tab
  .checkbox-group-list
  .checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 10px;
}
.activity-side-box
  .clients-box
  .forms-tab
  .checkbox-group-list
  .checkbox-group
  input {
  margin-left: 15px;
}
.activity-side-box
  .clients-box
  .forms-tab
  .checkbox-group-list
  .checkbox-group
  label {
  padding: 8px 15px;
}
.activity-side-box .clients-box .client-box-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}
.activity-side-box .clients-box .client-box-list .client-box {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
}
.activity-side-box .clients-box .client-box-list .client-box .client {
  background-color: rgba(10, 135, 207, 0.1);
  border-radius: 10px;
  text-align: center;
  padding: 15px;
}
.activity-side-box .clients-box .client-box-list .client-box .client p,
.activity-side-box .clients-box .client-box-list .client-box .client span {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.activity-side-box .clients-box .client-box-list .client-box .client p {
  margin-bottom: -5px;
}
.activity-side-box .clients-box .client-box-list .client-box .client span {
  color: rgba(33, 33, 33, 0.5);
}
.activity-side-box .records-box .records-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}
.activity-side-box .records-box .records-list .record-col {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
}
.activity-side-box .records-box .records-list .record {
  position: relative;
  display: block;
  padding: 15px;
  border-radius: 10px;
  background-color: rgba(10, 135, 207, 0.1);
  height: 100%;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.activity-side-box .records-box .records-list .record .record-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -15px;
}
.activity-side-box .records-box .records-list .record .record-details div {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 15px;
}
.activity-side-box .records-box .records-list .record .record-details p.title,
.activity-side-box
  .records-box
  .records-list
  .record
  .record-details
  span.description {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.activity-side-box .records-box .records-list .record .record-details p.title {
  color: #212121;
}
.activity-side-box
  .records-box
  .records-list
  .record
  .record-details
  span.description {
  display: block;
  color: rgba(33, 33, 33, 0.5);
}
.activity-side-box .records-box .records-list .record .record-details a {
  color: #0a87cf;
  word-break: break-all;
}
.activity-side-box .records-box .records-list .record .record-btns {
  background-color: #e9f3fb;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.activity-side-box .records-box .records-list .record .record-btns button {
  min-width: 70px;
}
.activity-side-box .records-box .records-list .record:hover {
  padding-bottom: 45px;
}
.activity-side-box .records-box .records-list .record:hover .record-btns {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.activity-side-box .rates-box .rates-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}
.activity-side-box .rates-box .rates-list .rate-col {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
}
.activity-side-box .rates-box .rates-list .rate {
  position: relative;
  display: block;
  padding: 15px;
  border-radius: 10px;
  background-color: rgba(10, 135, 207, 0.1);
  height: 100%;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.activity-side-box .rates-box .rates-list .rate .rate-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -15px;
}
.activity-side-box .rates-box .rates-list .rate .rate-details div {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 15px;
}
.activity-side-box .rates-box .rates-list .rate .rate-details .rate-currency,
.activity-side-box .rates-box .rates-list .rate .rate-details .rate-des {
  width: 100%;
}
.activity-side-box .rates-box .rates-list .rate .rate-details .rate-min,
.activity-side-box .rates-box .rates-list .rate .rate-details .rate-max {
  width: 50%;
}
.activity-side-box .rates-box .rates-list .rate .rate-details .rate-currency,
.activity-side-box .rates-box .rates-list .rate .rate-details .rate-min,
.activity-side-box .rates-box .rates-list .rate .rate-details .rate-max {
  text-align: center;
}
.activity-side-box .rates-box .rates-list .rate .rate-details p.title,
.activity-side-box .rates-box .rates-list .rate .rate-details span.description {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.activity-side-box .rates-box .rates-list .rate .rate-details p.title {
  color: #212121;
}
.activity-side-box .rates-box .rates-list .rate .rate-details span.description {
  display: block;
  color: rgba(33, 33, 33, 0.5);
}
.activity-side-box .rates-box .rates-list .rate .rate-details .rate-time span {
  display: block;
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
}
.activity-side-box .rates-box .rates-list .rate .rate-btns {
  background-color: #e9f3fb;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.activity-side-box .rates-box .rates-list .rate .rate-btns button {
  min-width: 70px;
}
.activity-side-box .rates-box .rates-list .rate:hover {
  padding-bottom: 45px;
}
.activity-side-box .rates-box .rates-list .rate:hover .rate-btns {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.influencers-info .activity-side-box .tab-content-box {
  display: none;
}
.influencers-info .activity-side-box .tab-content-box.active {
  display: block;
}
.influencers-info .activity-side-box .notes-box .notes-list .notes {
  position: relative;
  background-color: rgba(0, 179, 254, 0.05);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.influencers-info
  .activity-side-box
  .notes-box
  .notes-list
  .notes
  .note-details
  p.title {
  font-family: "avenirnextdemibold";
  font-size: 1rem;
  margin-bottom: 5px;
}
.influencers-info
  .activity-side-box
  .notes-box
  .notes-list
  .notes
  .note-details
  p.author {
  display: block;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-bottom: -5px;
}
.influencers-info
  .activity-side-box
  .notes-box
  .notes-list
  .notes
  .note-details
  p.description {
  display: block;
  font-family: "avenirnextmedium";
  font-size: 0.875rem;
  margin-bottom: -5px;
}
.influencers-info
  .activity-side-box
  .notes-box
  .notes-list
  .notes
  .note-details
  span {
  color: rgba(33, 33, 33, 0.5);
  font-family: "avenirnextmedium";
  font-size: 0.625rem;
}
.influencers-info .activity-side-box .notes-box .notes-list .notes .note-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  opacity: 0;
}
.influencers-info
  .activity-side-box
  .notes-box
  .notes-list
  .notes
  .note-btns
  button {
  min-width: 70px;
}
.influencers-info
  .activity-side-box
  .notes-box
  .notes-list
  .notes:hover
  .note-btns {
  opacity: 1;
}

.alert {
  position: fixed;
  bottom: 20px;
  left: 120px;
  z-index: 5;
  margin-bottom: 0;
}
.alert span.details {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.alert i {
  margin-right: 10px;
}

.push-notification {
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 25px 15px 20px;
  max-width: 300px;
}
.push-notification .close- {
  color: rgba(33, 33, 33, 0.2);
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  cursor: pointer;
}
.push-notification .box1 {
  padding-right: 15px;
}
.push-notification .box1 img {
  max-width: 40px;
}
.push-notification .box1 i {
  color: #0a87cf;
  font-size: 1.25rem;
}
.push-notification .box2 p {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.push-notification .box2 span {
  display: block;
  font-size: 0.75rem;
}

/* ===== MODULES ====== */
.login-background {
  background-color: #e6f5ff;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
}
.login-background .login-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  mix-blend-mode: luminosity;
  opacity: 0.03;
}

.login-panel {
  width: 97%;
}
.login-panel .row:first-of-type {
  margin-bottom: 30px;
}
.login-panel .row:first-of-type img {
  max-width: 50px;
  margin-bottom: 5px;
}
.login-panel form,
.login-panel .two-way-selection-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 25px;
}
.login-panel form .row,
.login-panel .two-way-selection-box .row {
  margin-bottom: 0 !important;
}
.login-panel form .form-inner-heading h2,
.login-panel .two-way-selection-box .form-inner-heading h2 {
  font-size: 1.25rem;
  margin-bottom: 15px;
}
.login-panel form .form-inner-heading p,
.login-panel .two-way-selection-box .form-inner-heading p {
  font-size: 0.875rem;
}
.login-panel form .form-inner-heading span.instructions,
.login-panel .two-way-selection-box .form-inner-heading span.instructions {
  cursor: pointer;
  color: #0a87cf;
}
.login-panel form .label-f-password,
.login-panel .two-way-selection-box .label-f-password {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.login-panel form .label-f-password a,
.login-panel .two-way-selection-box .label-f-password a {
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  margin-bottom: 6px;
}
.login-panel form .password-group,
.login-panel .two-way-selection-box .password-group {
  position: relative;
}
.login-panel form .password-group i,
.login-panel .two-way-selection-box .password-group i {
  color: rgba(33, 33, 33, 0.5);
  cursor: pointer;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
}
.login-panel form button,
.login-panel .two-way-selection-box button {
  width: 100%;
}
.login-panel form button img,
.login-panel .two-way-selection-box button img {
  margin-left: 10px;
}
.login-panel form .go-login-group,
.login-panel .two-way-selection-box .go-login-group {
  text-align: center;
}
.login-panel form .go-login-group p,
.login-panel .two-way-selection-box .go-login-group p {
  font-size: 0.875rem;
}
.login-panel form .form-group:last-of-type,
.login-panel .two-way-selection-box .form-group:last-of-type {
  margin-bottom: 0;
}
.login-panel form .login-info-text,
.login-panel .two-way-selection-box .login-info-text {
  text-align: center;
  font-size: 12px;
  margin-top: 15px;
}
.login-panel form .login-verification-code,
.login-panel .two-way-selection-box .login-verification-code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.login-panel form .login-verification-code input,
.login-panel .two-way-selection-box .login-verification-code input {
  color: #0a87cf;
  text-align: center;
  width: 50px;
  height: 50px;
  margin-left: 6px;
  margin-right: 6px;
  font-size: 30px;
  padding: 0;
}
.login-panel form .login-verification-code input::-webkit-input-placeholder,
.login-panel
  .two-way-selection-box
  .login-verification-code
  input::-webkit-input-placeholder {
  color: transparent;
}
.login-panel form .login-verification-code input::-moz-placeholder,
.login-panel
  .two-way-selection-box
  .login-verification-code
  input::-moz-placeholder {
  color: transparent;
}
.login-panel form .login-verification-code input:-ms-input-placeholder,
.login-panel
  .two-way-selection-box
  .login-verification-code
  input:-ms-input-placeholder {
  color: transparent;
}
.login-panel form .login-verification-code input::-ms-input-placeholder,
.login-panel
  .two-way-selection-box
  .login-verification-code
  input::-ms-input-placeholder {
  color: transparent;
}
.login-panel form .login-verification-code input::placeholder,
.login-panel
  .two-way-selection-box
  .login-verification-code
  input::placeholder {
  color: transparent;
}
.login-panel form .login-verification-code input:not(:-moz-placeholder-shown),
.login-panel
  .two-way-selection-box
  .login-verification-code
  input:not(:-moz-placeholder-shown) {
  border-color: #0a87cf;
}
.login-panel form .login-verification-code input:not(:-ms-input-placeholder),
.login-panel
  .two-way-selection-box
  .login-verification-code
  input:not(:-ms-input-placeholder) {
  border-color: #0a87cf;
}
.login-panel form .login-verification-code input:focus,
.login-panel form .login-verification-code input:not(:placeholder-shown),
.login-panel .two-way-selection-box .login-verification-code input:focus,
.login-panel
  .two-way-selection-box
  .login-verification-code
  input:not(:placeholder-shown) {
  border-color: #0a87cf;
}
.login-panel form .login-verification-code input::-webkit-outer-spin-button,
.login-panel form .login-verification-code input::-webkit-inner-spin-button,
.login-panel
  .two-way-selection-box
  .login-verification-code
  input::-webkit-outer-spin-button,
.login-panel
  .two-way-selection-box
  .login-verification-code
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.login-panel form .login-verification-code input[type="number"],
.login-panel
  .two-way-selection-box
  .login-verification-code
  input[type="number"] {
  -moz-appearance: textfield;
}
.login-panel form .resend-verification-code,
.login-panel .two-way-selection-box .resend-verification-code {
  margin-bottom: -12px;
}
.login-panel form .resend-verification-code p,
.login-panel .two-way-selection-box .resend-verification-code p {
  font-size: 0.875rem;
}
.login-panel form .resend-verification-code p span,
.login-panel .two-way-selection-box .resend-verification-code p span {
  cursor: pointer;
  color: #0a87cf;
}
.login-panel form .resend-verification-code button,
.login-panel .two-way-selection-box .resend-verification-code button {
  margin-top: 16px;
}
.login-panel form.verify-form.hidden,
.login-panel form.login-form.hidden,
.login-panel .two-way-selection-box.verify-form.hidden,
.login-panel .two-way-selection-box.login-form.hidden {
  display: none;
}
.login-panel form.verify-form.hidden,
.login-panel .two-way-selection-box.verify-form.hidden {
  display: none;
}
.login-panel form.verify-form .code-timer,
.login-panel .two-way-selection-box.verify-form .code-timer {
  color: #000;
  font-family: "avenirnextdemibold";
  font-weight: normal;
}
.login-panel form.verify-form .form-errors,
.login-panel .two-way-selection-box.verify-form .form-errors {
  margin-bottom: 10px;
}
.login-panel form.verify-form .form-group,
.login-panel .two-way-selection-box.verify-form .form-group {
  margin-top: 30px;
}
.login-panel form.verify-form .form-group .checkbox-group,
.login-panel .two-way-selection-box.verify-form .form-group .checkbox-group {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
}
.login-panel form.verify-form .form-group .checkbox-group input,
.login-panel form.verify-form .form-group .checkbox-group input::after,
.login-panel
  .two-way-selection-box.verify-form
  .form-group
  .checkbox-group
  input,
.login-panel
  .two-way-selection-box.verify-form
  .form-group
  .checkbox-group
  input::after {
  position: relative;
}
.login-panel form.verify-form .form-group .checkbox-group input::after,
.login-panel
  .two-way-selection-box.verify-form
  .form-group
  .checkbox-group
  input::after {
  display: block;
}
.login-panel form.verify-form .form-group .checkbox-group label,
.login-panel
  .two-way-selection-box.verify-form
  .form-group
  .checkbox-group
  label {
  font-family: "avenirnextmedium";
  font-weight: 400;
  margin-left: 10px;
}
.login-panel form button[disabled],
.login-panel .two-way-selection-box button[disabled] {
  pointer-events: none !important;
  background-color: rgba(33, 33, 33, 0.2);
  color: rgba(33, 33, 33, 0.5);
}
.login-panel form .two-way-selection-list,
.login-panel .two-way-selection-box .two-way-selection-list {
  margin-top: 30px;
}
.login-panel form .two-way-selection-list a:last-of-type .authentication-item,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  a:last-of-type
  .authentication-item {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.login-panel form .two-way-selection-list .authentication-item,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.login-panel form .two-way-selection-list .authentication-item span.label-,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item
  span.label- {
  position: relative;
  color: #212121;
  padding-left: 55px;
}
.login-panel form .two-way-selection-list .authentication-item span.label- i,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item
  span.label-
  i {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);
  font-size: 30px;
  margin-right: 15px;
}
.login-panel form .two-way-selection-list .authentication-item svg,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item
  svg {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.login-panel form .two-way-selection-list .authentication-item .lds-ellipsis,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item
  .lds-ellipsis {
  top: 0;
}
.login-panel
  form
  .two-way-selection-list
  .authentication-item
  .lds-ellipsis
  div,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item
  .lds-ellipsis
  div {
  width: 10px;
  height: 10px;
}
.login-panel form .two-way-selection-list .authentication-item:hover span,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item:hover
  span {
  color: #0a87cf;
}
.login-panel form .two-way-selection-list .authentication-item:hover svg,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item:hover
  svg {
  fill: #0a87cf;
}
.login-panel
  form
  .two-way-selection-list
  .authentication-item
  .recommended-badge,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item
  .recommended-badge {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
}
.login-panel
  form
  .two-way-selection-list
  .authentication-item
  .recommended-badge
  .badge,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item
  .recommended-badge
  .badge {
  background-color: #5cb85c;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 7px;
}
.login-panel
  form
  .two-way-selection-list
  .authentication-item
  .recommended-badge
  .badge
  i,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item
  .recommended-badge
  .badge
  i {
  font-size: 8px;
}
.login-panel
  form
  .two-way-selection-list
  .authentication-item
  .recommended-badge
  .badge-label,
.login-panel
  .two-way-selection-box
  .two-way-selection-list
  .authentication-item
  .recommended-badge
  .badge-label {
  color: #5cb85c;
  font-family: "avenirnextdemibold";
  font-size: 12px;
}
@media (min-width: 768px) {
  .login-panel {
    width: 450px;
  }
}

.google-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}
.google-modal .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.google-modal .modal-box {
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-radius: 10px;
  padding: 50px 40px;
  width: 550px;
}
.google-modal .modal-box .icon-close {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 30px;
}
.google-modal .modal-box .modal-heading {
  text-align: center;
  margin-bottom: 25px;
}
.google-modal .modal-box .modal-heading .icon-qmark {
  background-color: #0a87cf;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}
.google-modal .modal-box .modal-heading h2 {
  font-size: 1.25rem;
  margin-bottom: 15px;
}
.google-modal .modal-box .modal-heading p {
  font-size: 0.875rem;
}
.google-modal .modal-box .list-details {
  margin-bottom: 30px;
}
.google-modal .modal-box .list-details .detail {
  margin-bottom: 20px;
}
.google-modal .modal-box .list-details .detail:last-of-type {
  margin-bottom: 0;
}
.google-modal .modal-box .list-details .detail p,
.google-modal .modal-box .list-details .detail span.description {
  font-family: "avenirnextdemibold";
}
.google-modal .modal-box .list-details .detail p {
  font-size: 0.875rem;
  margin-bottom: 5px;
}
.google-modal .modal-box .list-details .detail span.description {
  display: block;
  color: rgba(33, 33, 33, 0.5);
  font-size: 0.75rem;
}
.google-modal .modal-box .list-details .detail .qr-sending {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
}
.google-modal .modal-box .list-details .detail .qr-sending span.btn-link {
  display: block;
  cursor: pointer;
  color: #0a87cf;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  text-decoration: underline;
}
.google-modal .modal-box .list-details .detail .qr-sending span.btn-link.sent {
  color: #681da8;
}
.google-modal .modal-box .list-details .detail .qr-sending .qr-sent {
  background-color: rgba(92, 184, 92, 0.2);
  color: #5cb85c;
  border-radius: 50%;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  margin-left: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.google-modal .modal-box .list-details .detail .qr-sending .lds-ring {
  top: -4px;
  margin-left: 8px;
}
.google-modal .modal-box .modal-bottom {
  text-align: center;
}
.google-modal .modal-box .modal-bottom button {
  width: 100%;
}
.google-modal .modal-box .modal-bottom p {
  font-size: 0.75rem;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
.google-modal.active {
  opacity: 1;
  visibility: visible;
}
.google-modal.active .overlay {
  opacity: 1;
  visibility: visible;
}

.profile-setting-section {
  padding: 20px 0;
  height: calc(100vh - 72px);
  overflow: auto;
}
.profile-setting-section .current-profile-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 768px) {
  .profile-setting-section .current-profile-details {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.profile-setting-section .current-profile-details .box {
  padding-left: 15px;
  padding-right: 15px;
}
.profile-setting-section .current-profile-details .profile-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #00b3fe;
  border-radius: 50%;
  width: 145px;
  height: 145px;
  overflow: hidden;
}

.profile-icon img {
  width: 110%;
  height: 110%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
}


.profile-setting-section .current-profile-details .profile-icon span {
  color: #fff;
  font-family: "avenirnextdemibold";
  font-size: 75px;
}
.profile-setting-section .current-profile-details .profile-icon .upload-photo {
  cursor: pointer;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: visible;
  z-index: 5;
}
.profile-setting-section
  .current-profile-details
  .profile-icon
  .upload-photo
  div {
  text-align: center;
}
.profile-setting-section
  .current-profile-details
  .profile-icon
  .upload-photo
  div
  span {
  display: block;
  font-family: "avenirnextmedium";
  font-size: 12px;
}
.profile-setting-section
  .current-profile-details
  .profile-icon
  .upload-photo
  input {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.profile-setting-section
  .current-profile-details
  .profile-icon:hover
  .upload-photo {
  opacity: 1;
  visibility: visible;
}
.profile-setting-section .current-profile-details .profile-detail p {
  font-family: "avenirnextdemibold";
  font-size: 1.25rem;
}
.profile-setting-section .current-profile-details .profile-detail span {
  font-family: "avenirnextmedium";
  font-size: 0.75rem;
  color: rgba(33, 33, 33, 0.5);
}
.profile-setting-section .tab-profile {
  margin-top: 30px;
  /* Firefox */
}
.profile-setting-section .tab-profile .control-tabs-box {
  position: relative;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 3;
}
.profile-setting-section .tab-profile .control-tabs-box .tabs-btn {
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 15px 30px;
  margin-bottom: -0.8px;
}
.profile-setting-section .tab-profile .control-tabs-box .tabs-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
  background-color: transparent;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.profile-setting-section .tab-profile .control-tabs-box .tabs-btn p {
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
}
.profile-setting-section .tab-profile .control-tabs-box .tabs-btn.active {
  background: rgba(10, 135, 207, 0.06);
  color: #0a87cf;
}
.profile-setting-section
  .tab-profile
  .control-tabs-box
  .tabs-btn.active::after {
  background-color: #0a87cf;
}
.profile-setting-section .tab-profile .content-tabs-box {
  padding: 20px 0 30px 0;
}
.profile-setting-section .tab-profile .content-tabs-box .tabs-content {
  display: none;
}
.profile-setting-section .tab-profile .content-tabs-box .tabs-content.active {
  display: block;
}
.profile-setting-section .tab-profile .content-tabs-box .forms-tab {
  width: 100%;
  max-width: 400px;
}
.profile-setting-section
  .tab-profile
  .content-tabs-box
  .forms-tab.change-password
  .input-group-box.full-box-
  .field-box {
  width: 60%;
}
.profile-setting-section
  .tab-profile
  .content-tabs-box
  .forms-tab
  .form-group-box
  .form-group-title
  label,
.profile-setting-section
  .tab-profile
  .content-tabs-box
  .forms-tab
  .form-group-box
  .input-group-box
  .field-box
  label {
  text-transform: capitalize;
}
.profile-setting-section
  .tab-profile
  .content-tabs-box
  .forms-tab
  .custom-basic-editor {
  margin-top: 30px;
}
.profile-setting-section
  .tab-profile
  .content-tabs-box
  .forms-tab
  .form-file-control {
  width: 100%;
  height: 35px;
  font-size: 12px;
  color: #212121;
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ced4da;
}
.profile-setting-section
  .tab-profile
  .content-tabs-box
  .forms-tab
  .form-file-control::-webkit-file-upload-button {
  margin-top: 0;
  margin-right: 10px;
  border: none;
  background: #23a7f4;
  padding: 0 10px;
  height: 23px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}
.profile-setting-section
  .tab-profile
  .content-tabs-box
  .forms-tab
  .form-file-control::file-selector-button {
  margin-top: 0;
  margin-right: 10px;
  border: none;
  background: #23a7f4;
  padding: 0 10px;
  height: 23px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}
.profile-setting-section .tab-profile input#contact::-webkit-outer-spin-button,
.profile-setting-section .tab-profile input#contact::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.profile-setting-section .tab-profile input[type="number"]#contact {
  -moz-appearance: textfield;
}
.profile-setting-section .btn-groups {
  margin-top: 20px;
}
.profile-setting-section .btn-groups button {
  margin-right: 20px;
}

.member-table-section .table-view.table-interval .switch-tggle {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}
.member-table-section .table-view.table-interval .switch-tggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.member-table-section .table-view.table-interval .switch-tggle .slider {
  position: relative;
  cursor: pointer;
  background-color: rgba(33, 33, 33, 0.2);
  border-radius: 35px;
  width: 47px;
  height: 22px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.member-table-section .table-view.table-interval .switch-tggle .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0;
  bottom: 3px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}
.member-table-section
  .table-view.table-interval
  .switch-tggle
  input:checked
  + .slider {
  background-color: #0a87cf;
}
.member-table-section
  .table-view.table-interval
  .switch-tggle
  input:focus
  + .slider {
  -webkit-box-shadow: 0 0 1px #0a87cf;
  box-shadow: 0 0 1px #0a87cf;
}
.member-table-section
  .table-view.table-interval
  .switch-tggle
  input:checked
  + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
}
.member-table-section .table-view.table-interval .switch-tggle i {
  display: none;
  color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.member-table-section .table-view.table-interval .switch-tggle i.fa-check {
  right: 8px;
  font-size: 10px;
}
.member-table-section .table-view.table-interval .switch-tggle i.fa-times {
  left: 8px;
  font-size: 12px;
}
.member-table-section .table-view.table-interval .switch-tggle .tggle-txt {
  display: none;
}
.member-table-section .table-view.table-interval .avatar-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.member-table-section .table-view.table-interval .avatar-info .custom-avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}
.member-table-section
  .table-view.table-interval
  .avatar-info
  .custom-avatar
  span {
  background-color: #00b3fe;
  color: #fff;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "avenirnextdemibold";
  font-size: 0.875rem;
  width: 35px;
  height: 35px;
}
.member-table-section .table-view.table-interval .avatar-info .name-info span {
  display: block;
}
.member-table-section
  .table-view.table-interval
  .avatar-info
  .name-info
  .main-name
  span {
  color: #212121;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.member-table-section
  .table-view.table-interval
  .avatar-info
  .name-info
  .others-info
  span {
  float: left;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  margin-right: 0;
  font-size: 0.625rem;
}
.member-table-section
  .table-view.table-interval
  .avatar-info
  .name-info
  .others-info
  span:last-of-type {
  margin-right: 0;
}
.member-table-section
  .table-view.table-interval
  .avatar-info
  .name-info
  .others-info
  span
  .fa-check-circle {
  color: #23a7f4;
  margin-left: 3px;
}
.member-table-section .table-view.table-interval .ig-parameters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.member-table-section
  .table-view.table-interval
  .ig-parameters
  span.param-text {
  position: relative;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 2px 4px;
  margin-top: 4px;
  margin-right: 4px;
  font-size: 0.75rem;
}
.member-table-section
  .table-view.table-interval
  .ig-parameters
  span.param-text
  i.operator-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #0a87cf;
  color: #fff;
  border-radius: 4px;
  width: 15px;
  height: 15px;
  font-style: normal;
}
.member-table-section
  .table-view.table-interval
  .ig-parameters
  span.param-text
  i.fa-times-circle {
  cursor: pointer;
  position: absolute;
  top: -3px;
  right: -5px;
  color: red;
  font-size: 0.75rem;
}
.member-table-section .table-view.table-interval .ig-parameters p {
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
}
.member-table-section .table-view.table-interval .ig-parameters .ig-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0a87cf;
  color: #fff;
  border-radius: 5px;
  font-family: "avenirnextdemibold";
  font-size: 0.75rem;
  padding: 1px 8px;
}
.member-table-section .table-view.table-interval .actions-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.member-table-section .table-view.table-interval .actions-btn button {
  margin-left: 8px;
  margin-right: 8px;
}

.custom-input {
  border: 1px solid;
  background-color: #fff;
  border: 1px solid rgba(112, 112, 112, 0.2);
  border-radius: 10px;
}
.custom-input input {
  cursor: pointer;
  width: 100%;
  padding: 10px;
}
.custom-input input::file-selector-button {
  background-color: #0a87cf;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
}

.btn-text-disabled {
  opacity: 1;
  background-color: rgba(33, 33, 33, 0.1) !important;
  padding: 4px 8px !important;
  border-radius: 4px;
  color: #ffffff !important;
  cursor: not-allowed !important;
}

.ml-2 {
  margin-left: 2px;
}